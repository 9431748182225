import * as d3 from 'd3';
import collapseOther from '../math/collapseOther';
import ShapForceChart from './ShapForceChart';
import WithMarkers from './WithMarkers';

class ShapForceLinesChart extends ShapForceChart {
  constructor(...args) {
    super(...args);

    this.chart.classed('chart-coordinates', true);
  }

  static buildStack(data, labels) {
    return d3.stack()
      .offset(d3.stackOrderAppearance)
      .keys(labels)
      .value((d, key) => {
        const entry = d.features[key];
        return entry ? entry.importance : 0;
      })(data);
  }

  render(data, meta = {}, sortIndex = 0) {
    super.render();

    const topLabels = Object.keys(data[sortIndex].features).slice(0, 10);
    const topValues = collapseOther(data, topLabels);

    const labels = Object.keys(topValues[sortIndex].features);
    const values = this.constructor.buildStack(topValues, labels);

    const xDomain = new Set(data.map((d) => d.date));
    const yDomain = [
      d3.min(values, (d) => d3.min(d, (p) => p[1])),
      d3.max(values, (d) => d3.max(d, (p) => p[1])),
    ];

    this.updateDomainX(xDomain);
    this.updateDomainY(yDomain);

    const colors = d3.scaleOrdinal()
      .domain(labels)
      .range([
        '#66cc99', '#336666', '#ff9999', '#993333', '#66ccff',
        '#0099cc', '#6666ff', '#333399', '#ffcc33', '#996633',
        '#cccccc',
      ]);

    const shape = d3.line()
      .curve(d3.curveBasis)
      .x((d) => this.xScale(d.data.date))
      .y((d) => this.yScale(d[1]));

    const groups = this.shapes.selectAll('.chart-series')
      .data(values);

    groups.exit().remove();

    const newGroups = groups.enter().append('g')
      .classed('chart-series', true);

    newGroups.append('path')
      .classed('chart-series-line', true)
      .attr('fill', 'none')
      .attr('stroke', 'currentColor');

    const allGroups = newGroups.merge(groups);

    allGroups.attr('color', (d) => colors(d.key));
    allGroups.select('.chart-series-line')
      .attr('d', shape);

    this.updateTooltips(topValues, meta);

    allGroups.on('mouseleave.tooltip', this.hideTooltip.bind(this));
    allGroups.on('mousemove.tooltip', this.showTooltip.bind(this));
    allGroups.on('mouseenter.tooltip', (event) => d3.select(event.target).raise());
  }
}

export default WithMarkers(ShapForceLinesChart);
