import * as d3 from 'd3';
import Chart from './Chart';
import WithMarkers from './WithMarkers';

class PredictionsChart extends Chart {
  constructor(...args) {
    super(...args);

    this.tooltipPredLabel = 'Predicted';
    this.tooltipRealLabel = 'Actual   ';

    this.chart.classed('chart-histogram', true)
      .on('mouseleave.tooltip', this.hideTooltipValues.bind(this))
      .on('mousemove.tooltip', this.showTooltipValues.bind(this));

    this.realSeries = this.shapes.append('g')
      .classed('chart-series', true)
      .attr('color', '#000000');

    this.realLine = this.realSeries.append('path')
      .classed('chart-series-line', true)
      .attr('fill', 'none')
      .attr('stroke', 'currentColor')
      .style('stroke-dasharray', 1)
      .style('stroke-width', 1);

    this.predSeries = this.shapes.append('g')
      .classed('chart-series', true)
      .attr('color', '#993333');

    this.predLine = this.predSeries.append('path')
      .classed('chart-series-line', true)
      .attr('fill', 'none')
      .attr('stroke', 'currentColor')
      .style('stroke-width', 2);

    this.tooltipScale = d3.scaleQuantile()
      .domain([this.margin.left, this.width - this.margin.right]);

    this.tooltipReal = this.chart.append('text')
      .classed('chart-tooltip', true)
      .attr('x', 24 + 16)
      .attr('y', 24)
      .attr('dominant-baseline', 'central')
      .attr('fill', 'currentColor')
      .attr('font-size', 10)
      .attr('text-anchor', 'start')
      .style('white-space', 'pre');

    this.tooltipRealColor = this.chart.append('rect')
      .classed('chart-legend', true)
      .attr('fill', '#000000')
      .attr('x', 24)
      .attr('y', 19)
      .attr('height', 10)
      .attr('width', 10);

    this.tooltipPred = this.chart.append('text')
      .classed('chart-tooltip', true)
      .attr('x', 24 + 16)
      .attr('y', 36)
      .attr('dominant-baseline', 'central')
      .attr('fill', 'currentColor')
      .attr('font-size', 10)
      .attr('text-anchor', 'start')
      .style('white-space', 'pre');

    this.tooltipPredColor = this.chart.append('rect')
      .classed('chart-legend', true)
      .attr('fill', '#993333')
      .attr('x', 24)
      .attr('y', 31)
      .attr('height', 10)
      .attr('width', 10);

    this.hideTooltipValues();
  }

  showTooltipValues(event) {
    const mouseX = d3.pointer(event)[0];
    const point = this.tooltipScale(mouseX);

    if (point) {
      const real = this.tooltipRealFormat(point.actual);
      const pred = this.tooltipPredFormat(point.predicted);

      this.tooltipReal.text(`${this.tooltipRealLabel} ${real}`);
      this.tooltipPred.text(`${this.tooltipPredLabel} ${pred}`);
    }
  }

  hideTooltipValues() {
    this.tooltipReal.text(this.tooltipRealLabel);
    this.tooltipPred.text(this.tooltipPredLabel);
  }

  render(data, min = 0, max = 99999) {
    const labels = ['actual', 'predicted'];

    const stack = d3.stack()
      .offset(d3.stackOrderAppearance)
      .keys(labels)
      .value((d, key) => d[key])(data);

    const xDomain = new Set(data.map((d) => d.date));
    const yDomain = [
      Math.min(min, d3.min(stack, (d) => d3.min(d, (p) => p[1]))),
      Math.max(max, d3.max(stack, (d) => d3.max(d, (p) => p[1]))),
    ];

    this.updateDomainX(xDomain);
    this.updateDomainY(yDomain);

    const realLineShape = d3.line()
      .x((d) => this.xScale(d.data.date))
      .y((d) => this.yScale(d[1]));

    this.realLine.datum(stack[0])
      .attr('d', realLineShape);

    const predLineShape = d3.line()
      .curve(d3.curveBasis)
      .x((d) => this.xScale(d.data.date))
      .y((d) => this.yScale(d[1]));

    this.predLine.datum(stack[1])
      .attr('d', predLineShape);

    this.tooltipRealFormat = d3.format(' > 6.3f');
    this.tooltipPredFormat = d3.format(' > 6.3f');
    this.tooltipScale.range(data);

    this.hideTooltipValues();
  }
}

export default WithMarkers(PredictionsChart);
