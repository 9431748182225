import * as d3 from 'd3';
import Chart from './Chart';

export default class ShapChart extends Chart {
  constructor(...args) {
    super(...args);

    this.tooltipLocationLabel = 'Selected Location';
    this.tooltipAverageLabel = 'Dataset Average  ';

    this.tooltipScale = d3.scaleQuantile()
      .domain([this.margin.left, this.width - this.margin.right]);

    this.tooltipLabel = this.chart.append('text')
      .classed('chart-tooltip', true)
      .attr('x', 24)
      .attr('y', 24)
      .attr('display', 'none')
      .attr('dominant-baseline', 'central')
      .attr('fill', 'currentColor')
      .attr('font-size', 10)
      .attr('font-weight', 'bold')
      .attr('text-anchor', 'start')
      .style('white-space', 'pre');

    this.tooltipLocation = this.chart.append('text')
      .classed('chart-tooltip', true)
      .attr('x', 24)
      .attr('y', 36)
      .attr('display', 'none')
      .attr('dominant-baseline', 'central')
      .attr('fill', 'currentColor')
      .attr('font-size', 10)
      .attr('text-anchor', 'start')
      .style('white-space', 'pre');

    this.tooltipAverage = this.chart.append('text')
      .classed('chart-tooltip', true)
      .attr('x', 24)
      .attr('y', 48)
      .attr('display', 'none')
      .attr('dominant-baseline', 'central')
      .attr('fill', 'currentColor')
      .attr('font-size', 10)
      .attr('text-anchor', 'start')
      .style('white-space', 'pre');
  }

  updateTooltips(data, features) {
    this.tooltipLookup = features;
    this.tooltipLocationFormat = d3.format(' > 7.2f');
    this.tooltipAverageFormat = d3.format(' > 7.2f');
    this.tooltipScale.range(data);
  }

  hideTooltip() {
    this.tooltipLabel.attr('display', 'none');
    this.tooltipLocation.attr('display', 'none');
    this.tooltipAverage.attr('display', 'none');
  }

  showTooltip(event, d) {
    const mouseX = d3.pointer(event)[0];
    const point = this.tooltipScale(mouseX).features[d.key];

    // TODO: backwards compatility
    // This deals with features that are not present in metadata.json
    // https://github.com/kunumi/covid-19-forecasting/issues/160
    const { label, unit } = {
      label: `[ "${d.key}" ]`, unit: '', ...this.tooltipLookup[d.key],
    };

    this.tooltipLabel.attr('display', null);
    this.tooltipLabel.text(label);

    if (point.value) {
      const location = this.tooltipLocationFormat(point.value);
      this.tooltipLocation.attr('display', null);
      this.tooltipLocation.text(`${this.tooltipLocationLabel} ${location} ${unit}`);
    }
    if (point.global_mean) {
      const average = this.tooltipAverageFormat(point.global_mean);
      this.tooltipAverage.attr('display', null);
      this.tooltipAverage.text(`${this.tooltipAverageLabel} ${average} ${unit}`);
    }
  }
}
