import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PredictionsChart from '../charts/PredictionsChart';

function Predictions({
  data,
  min,
  max,
  markers,
  selected,
}) {
  const chart = useRef();

  const container = useCallback((node) => {
    if (node === null) return;
    chart.current = new PredictionsChart(node);
  }, []);

  const { t } = useTranslation();

  useEffect(() => {
    chart.current.relativeDateLabel = t('charts.relativeDate');
    chart.current.tooltipRealLabel = t('charts.predictions.real');
    chart.current.tooltipPredLabel = t('charts.predictions.pred');
  }, [chart, t]);

  useEffect(() => {
    chart.current.render(data, min, max);
  }, [chart, t, data, min, max]);

  useEffect(() => {
    chart.current.renderMarkers(data, markers);
  }, [chart, data, markers]);

  useEffect(() => {
    chart.current.selectMarker(selected);
  }, [chart, selected]);

  return (
    <div ref={container}></div>
  );
}

Predictions.propTypes = {
  data: PropTypes.array.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  selected: PropTypes.number,
  markers: PropTypes.array,
};

export default Predictions;
