import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

function Methodology({ meta }) {
  const { t } = useTranslation();

  const featureData = [];
  const featureI18n = t('features', { returnObjects: true });
  const baseFeatureI18n = t('baseFeature', { returnObjects: true });

  meta.features.forEach((key) => {
    featureData.push({
      key,
      label: key,
      ...baseFeatureI18n,
      ...featureI18n[key],
    });
  });

  const groups = new Map();

  featureData
    .sort((a, b) => `${a.source}`.localeCompare(b.source) || `${a.label}`.localeCompare(b.label))
    .forEach((feature) => {
      if (!groups.has(feature.source)) groups.set(feature.source, []);
      groups.get(feature.source).push(feature);
    });

  const groupedFeatures = Array.from(groups.entries());

  return (
    <div className="container">
      <aside className="sidebar">
        <h2 className="section-title">{t('methodology.sidebar.title')}</h2>
        {t('methodology.sidebar.body', { returnObjects: true }).map((text, i) => (
          <p key={i} dangerouslySetInnerHTML={{ __html: text }} />
        ))}
      </aside>
      <main className="main">
        <h2 className="main-title">{t('methodology.title')}</h2>
        {t('methodology.intro', { returnObjects: true }).map((text, i) => (
          <p key={i} dangerouslySetInnerHTML={{ __html: text }} />
        ))}
        <section className="section">
          <h3 className="section-title">{t('methodology.sources.title')}</h3>
          {t('methodology.sources.body', { returnObjects: true }).map((text, i) => (
            <p key={i} dangerouslySetInnerHTML={{ __html: text }} />
          ))}
        </section>
        <section className="section">
          <h2 className="section-title">{t('methodology.locations.title')}</h2>
          {t('methodology.locations.body', { returnObjects: true }).map((text, i) => (
            <p key={i} dangerouslySetInnerHTML={{ __html: text }} />
          ))}
        </section>
        <section className="section">
          <h2 className="section-title">{t('methodology.training.title')}</h2>
          {t('methodology.training.body', { returnObjects: true }).map((text, i) => (
            <p key={i} dangerouslySetInnerHTML={{ __html: text }} />
          ))}
        </section>
        <section className="section">
          <h2 className="section-title">{t('methodology.glossary.title')}</h2>
          {t('methodology.glossary.body', { returnObjects: true }).map((text, i) => (
            <p key={i} dangerouslySetInnerHTML={{ __html: text }} />
          ))}
          <div className="feature-list">
            {groupedFeatures.map(([group, features]) => (
              <React.Fragment key={group}>
                <h3>{group}</h3>
                <dl>
                  {features.map((feature) => (
                    <React.Fragment key={feature.key}>
                      <dt>
                        <a href={feature.url}>{feature.label}</a>
                      </dt>
                      <dd>
                        <strong>{feature.category}</strong> • {feature.description}
                      </dd>
                    </React.Fragment>
                  ))}
                </dl>
              </React.Fragment>
            ))}
          </div>
        </section>
      </main>
    </div>
  );
}

Methodology.propTypes = {
  meta: PropTypes.object.isRequired,
};

export default Methodology;
