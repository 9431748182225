import PropTypes from 'prop-types';
import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';

const TRACKING_ID = process.env.NODE_ENV === 'production'
  ? 'UA-172374313-1'
  : 'UA-172374313-2';

function TrackedRoute({ children }) {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
  }, []);

  useEffect(() => {
    const { pathname, search, hash } = window.location;

    ReactGA.ga('send', 'pageview', pathname + search + hash);
  }, [location]);

  return children;
}

TrackedRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TrackedRoute;
