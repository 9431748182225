import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ShapForceLineChart from '../charts/ShapForceLineChart';

function ShapForceLine({
  data,
  sortIndex,
  markers,
  selected,
}) {
  const chart = useRef();

  const container = useCallback((node) => {
    if (node === null) return;

    chart.current = new ShapForceLineChart(node);
  }, []);

  const { t } = useTranslation();

  useEffect(() => {
    chart.current.leadsToLabel = t('charts.shapForce.leadsTo');
    chart.current.lessDeathsLabel = t('charts.shapForce.lessDeaths');
    chart.current.moreDeathsLabel = t('charts.shapForce.moreDeaths');
    chart.current.relativeDateLabel = t('charts.relativeDate');
    chart.current.tooltipLocationLabel = t('charts.shapForce.location');
    chart.current.tooltipAverageLabel = t('charts.shapForce.average');
  }, [t]);

  useEffect(() => {
    chart.current.render(data, t('features', { returnObjects: true }), sortIndex);
  }, [container, t, data, sortIndex]);

  useEffect(() => {
    chart.current.renderMarkers(data, markers);
  }, [chart, data, markers]);

  useEffect(() => {
    chart.current.selectMarker(selected);
  }, [chart, selected]);

  return (
    <div ref={container}></div>
  );
}

ShapForceLine.propTypes = {
  data: PropTypes.array.isRequired,
  sortIndex: PropTypes.number.isRequired,
  selected: PropTypes.number,
  markers: PropTypes.array,
};

export default ShapForceLine;
