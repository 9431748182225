import * as d3 from 'd3';

const DEFAULT_WIDTH = 800;
const DEFAULT_HEIGHT = 450;
const DEFAULT_MARGINS = {
  top: 72,
  right: 24,
  bottom: 48,
  left: 60,
};

export default class Chart {
  constructor(selector, width = DEFAULT_WIDTH, height = DEFAULT_HEIGHT, margin = DEFAULT_MARGINS) {
    this.width = width;
    this.height = height;
    this.margin = margin;

    this.innerWidth = this.width - this.margin.left - this.margin.right;
    this.innerHeight = this.height - this.margin.top - this.margin.bottom;

    this.container = d3.select(selector)
      .classed('chart-container', true);

    this.chart = this.container.append('svg')
      .classed('chart', true)
      .attr('height', this.height)
      .attr('viewBox', [0, 0, this.width, this.height])
      .attr('width', this.width);

    this.xGrid = this.chart.append('g')
      .classed('chart-grid', true)
      .classed('chart-grid-x', true);

    this.yGrid = this.chart.append('g')
      .classed('chart-grid', true)
      .classed('chart-grid-y', true);

    this.xAxis = this.chart.append('g')
      .classed('chart-axis', true)
      .classed('chart-axis-x', true)
      .attr('transform', `translate(0, ${this.height - this.margin.bottom})`);

    this.yAxis = this.chart.append('g')
      .classed('chart-axis', true)
      .classed('chart-axis-y', true)
      .attr('transform', `translate(${this.margin.left}, 0)`);

    this.shapes = this.chart.append('g')
      .classed('chart-shapes', true);

    this.rule = this.chart.append('line')
      .classed('chart-rule', true)
      .attr('y1', this.margin.top)
      .attr('y2', this.height - this.margin.bottom)
      .attr('display', 'none')
      .attr('opacity', 0.5)
      .attr('stroke', '#000000')
      .attr('stroke-dasharray', 2)
      .attr('stroke-width', 1);

    this.absoluteDateInfo = this.chart.append('text')
      .classed('chart-tooltip', true)
      .attr('x', this.width - 24)
      .attr('y', 24)
      .attr('display', 'none')
      .attr('dominant-baseline', 'central')
      .attr('fill', 'currentColor')
      .attr('font-size', 10)
      .attr('font-weight', 'bold')
      .attr('text-anchor', 'end');

    this.relativeDateLabel = 'days since 1st death';
    this.relativeDateInfo = this.chart.append('text')
      .classed('chart-tooltip', true)
      .attr('x', this.width - 24)
      .attr('y', 36)
      .attr('display', 'none')
      .attr('dominant-baseline', 'central')
      .attr('fill', 'currentColor')
      .attr('font-size', 10)
      .attr('text-anchor', 'end');

    this.xAxisShape = d3.axisBottom();
    this.yAxisShape = d3.axisLeft();
  }

  updateDomainX(domain, scale = d3.scaleUtc) {
    this.xBandwidth = this.innerWidth / (domain.length - 1);
    this.xScale = scale()
      .range([this.margin.left, this.width - this.margin.right])
      .domain(d3.extent(domain))
      .clamp(true);

    const tickValues = d3.utcMonths(d3.min(domain), d3.max(domain), 1);

    this.xAxisFormat = d3.utcFormat('%b/%y');
    this.xAxisShape.scale(this.xScale)
      .tickFormat(this.xAxisFormat)
      .tickPadding(9)
      .tickSize(9)
      .tickValues(tickValues);

    this.xAxis.call(this.xAxisShape);

    this.xGrid.selectAll('.chart-grid-line')
      .data(tickValues)
      .join('line')
      .classed('chart-grid-line', true)
      .attr('opacity', 0.05)
      .attr('stroke', '#000000')
      .attr('stroke-width', 1)
      .attr('x1', (d) => this.xScale(d) + 0.5)
      .attr('y1', this.margin.top)
      .attr('x2', (d) => this.xScale(d) + 0.5)
      .attr('y2', this.height - this.margin.bottom);

    this.infoFormat = d3.utcFormat('%x');
    this.infoStep = d3.utcDay.every(1);

    this.chart.on('mouseenter.info', this.showInfo.bind(this));
    this.chart.on('mousemove.info', this.updateInfo.bind(this));
    this.chart.on('mouseleave.info', this.hideInfo.bind(this));
  }

  updateDomainY(domain, scale = d3.scaleLinear) {
    this.yScale = scale()
      .range([this.height - this.margin.bottom, this.margin.top])
      .domain(domain)
      .nice();

    const tickValues = scale === d3.scaleLog
      ? this.yScale.ticks(4)
      : this.yScale.ticks(10);

    this.yAxisFormat = d3.format('');
    this.yAxisShape.scale(this.yScale)
      .tickFormat(this.yAxisFormat)
      .tickPadding(9)
      .tickSize(0)
      .tickValues(tickValues);

    this.yAxis.call(this.yAxisShape);

    this.yGrid.selectAll('.chart-grid-line')
      .data(tickValues)
      .join('line')
      .classed('chart-grid-line', true)
      .attr('opacity', 0.05)
      .attr('stroke', '#000000')
      .attr('stroke-width', 1)
      .attr('x1', this.margin.left)
      .attr('y1', (d) => this.yScale(d) + 0.5)
      .attr('x2', this.width - this.margin.right)
      .attr('y2', (d) => this.yScale(d) + 0.5);
  }

  showInfo() {
    this.absoluteDateInfo.attr('display', null);
    this.relativeDateInfo.attr('display', null);

    this.rule.attr('display', null);
  }

  hideInfo() {
    this.absoluteDateInfo.attr('display', 'none');
    this.relativeDateInfo.attr('display', 'none');

    this.rule.attr('display', 'none');
  }

  updateInfo(event) {
    const mouseX = d3.pointer(event)[0];
    const scaleX = this.xScale.invert(mouseX).getTime();

    const absoluteDate = this.infoStep.round(scaleX);
    const relativeDate = this.infoStep.count(this.xScale.domain()[0], absoluteDate) + 1;
    const ruleX = this.xScale(absoluteDate) + 0.5;

    this.absoluteDateInfo.text(this.infoFormat(absoluteDate));
    this.relativeDateInfo.text(`${relativeDate} ${this.relativeDateLabel}`);
    this.rule.attr('transform', `translate(${ruleX}, 0)`);
  }
}
