export default function collapseOther(data, labels) {
  const set = new Set(labels);

  return data.map(({ features, ...rest }) => {
    const final = {};
    let total = 0;

    Object.entries(features).forEach(([k, v]) => {
      if (set.has(k)) final[k] = v;
      else total += v.importance;
    });

    final['...rest'] = { importance: total };

    return { ...rest, features: final };
  });
}
