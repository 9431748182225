import React from 'react';
import { useTranslation } from 'react-i18next';

function Faq() {
  const { t } = useTranslation();

  return (
    <div className="container">
      <aside className="sidebar">
        <h2 className="section-title">{t('faq.sidebar.title')}</h2>
        {t('faq.sidebar.body', { returnObjects: true }).map((text, i) => (
          <p key={i} dangerouslySetInnerHTML={{ __html: text }} />
        ))}
      </aside>
      <main className="main">
        <h2 className="main-title">{t('faq.title')}</h2>
        <dl className="faq">
          {t('faq.questions', { returnObjects: true }).map(({ question, answer }, i) => (
            <React.Fragment key={i}>
              <dt>{question}</dt>
              <dd>{answer}</dd>
            </React.Fragment>
          ))}
        </dl>
      </main>
    </div>
  );
}

export default Faq;
