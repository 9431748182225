import { ROOT } from './config';

async function loadVersions(root = ROOT) {
  const response = await fetch(`${root}/latest.json`);
  const data = await response.json();

  return {
    latest: data.latest,
    versions: data.versions.reverse(),
  };
}

export default loadVersions;
