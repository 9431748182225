import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ShapWaterfallChart from '../charts/ShapWaterfallChart';

function ShapWaterfall({ data }) {
  const chart = useRef();

  const container = useCallback((node) => {
    if (node === null) return;

    chart.current = new ShapWaterfallChart(node);
  }, []);

  const { t } = useTranslation();

  useEffect(() => {
    chart.current.predictionLabel = t('charts.shapWaterfall.prediction');
    chart.current.tooltipLocationLabel = t('charts.shapForce.location');
    chart.current.tooltipAverageLabel = t('charts.shapForce.average');
  }, [t]);

  useEffect(() => {
    chart.current.render(data, t('features', { returnObjects: true }));
  }, [container, t, data]);

  return (
    <div ref={container}></div>
  );
}

ShapWaterfall.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ShapWaterfall;
