export default {
  title: 'O Que Afeta A Mortalidade Por COVID-19',

  format: {
    decimal: ',',
    thousands: '.',
    grouping: [3],
    currency: ['R$', ''],
  },

  timeFormat: {
    dateTime: '%A, %e de %B de %Y. %X',
    date: '%d/%m/%Y',
    time: '%H:%M:%S',
    periods: ['AM', 'PM'],
    days: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
    shortDays: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
    months: [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ],
    shortMonths: [
      'Jan',
      'Fev',
      'Mar',
      'Abr',
      'Mai',
      'Jun',
      'Jul',
      'Ago',
      'Set',
      'Out',
      'Nov',
      'Dez',
    ],
  },

  nav: {
    world: 'Mundo',
    brazil: 'Brasil',
    home: 'Início',
    methodology: 'Metodologia',
    faq: 'FAQ',
  },

  charts: {
    relativeDate: 'dias desde a 1ª morte',
    predictions: {
      real: 'Real   ',
      pred: 'Predito',
    },
    shapForce: {
      leadsTo: 'Indica',
      moreDeaths: 'MAIS MORTES',
      lessDeaths: 'MENOS MORTES',
      location: 'País selecionado',
      average: 'Média do dataset',
    },
    shapWaterfall: {
      prediction: 'Predição',
      otherFeatures: 'outras variáveis',
    },
  },

  home: {
    selectLocation: 'Selecione o País',
    sidebar: {
      heading:
        'Esta ferramenta <strong>exibe duas métricas para avaliar a variação nas taxas de mortalidade devido à COVID-19 e identifica as variáveis que mais influenciam estes números</strong> no pico epidêmico local.',
      body:
        'Nossos modelos de aprendizado de máquina levam em conta dados de dezenas de fontes, incluindo número de mortes por COVID-19, dados de viagens, indicadores de infraestrutura de saúde, políticas públicas e dados demográficos gerais (como Índice de Gini, PIB, distribuição etária, etc). <0>A metodologia completa, paper e fontes de dados podem ser encontrados aqui.</0>',
      updatedAt: 'Atualizado em:',
    },
    velocity: {
      histogram: {
        title: 'Taxa de mortalidade diária',
        description:
          'Este gráfico mostra o número diário de mortes por 100 mil pessoas (métrica expressa no paper como “velocidade”) — suavizado por uma média móvel de 7 dias. A escala vertical pode variar em cada seleção.',
      },
      peak: {
        title: 'Selecione um pico',
        description: 'Escolha uma data para ver a influência das variáveis nos gráficos seguintes.',
      },
      waterfall: {
        title: 'Variáveis mais impactantes no pico selecionado',
        description:
          'Top 10 variáveis que mais influenciam a predição do modelo, em ordem decrescente do topo para a base. Variáveis em vermelho levam a uma predição mais pessimista, enquanto as em azul levam a uma predição mais otimista — o grau de impacto em qualquer direção é representado pela largura da barra.',
      },
      featuresArea: {
        title: 'Impacto das variáveis ao longo do tempo',
        description:
          'Este gráfico mostra o impacto que as variáveis do pico selecionado têm na taxa diária de mortes desde a primeira morte local. A área de cada variável no gráfico revela seu impacto na predição. Valores localizados acima da linha base horizontal indicam que a variável leva a uma predição mais pessimista, enquanto aqueles abaixo levam a uma predição mais otimista.',
      },
      featuresLine: {
        title: 'Impacto das variáveis ao longo do tempo',
        description:
          'Este gráfico mostra os mesmos dados que acima, exibidos como linhas individuais. Suas posições a partir da linha base indicam o impacto no resultado: variáveis localizadas acima da linha base horizontal levam a uma predição mais pessimista, enquanto aquelas abaixo levam a uma predição mais otimista.',
      },
    },
    acceleration: {
      histogram: {
        title: 'Variação em 14 dias da taxa de mortalidade',
        description:
          'Este gráfico mostra o número de mortes por 100 mil pessoas de um dia específico, menos o mesmo dado 14 dias antes (métrica expressa no paper como “aceleração”). A linha é suavizada por uma média móvel de 7 dias.',
      },
      peak: {
        title: 'Selecione um pico',
        description: 'Escolha uma data para ver a influência das variáveis nos gráficos seguintes.',
      },
      waterfall: {
        title: 'Variáveis mais impactantes no pico da variação diária de mortes',
        description:
          'Top 10 variáveis que mais influenciam a predição do modelo, em ordem decrescente do topo para a base. Variáveis em vermelho levam a uma predição mais pessimista, enquanto as em azul levam a uma predição mais otimista. O grau de impacto em qualquer direção é representado pela largura da barra.',
      },
      featuresArea: {
        title: 'Impacto das variáveis ao longo do tempo',
        description:
          'Este gráfico mostra o impacto que as variáveis do pico selecionado têm na variação da taxa de mortes desde a primeira morte local. A área de cada variável no gráfico revela seu impacto na predição. Valores localizados acima da linha base horizontal indicam que a variável leva a uma predição mais pessimista, enquanto aqueles abaixo levam a uma predição mais otimista.',
      },
      featuresLine: {
        title: 'Impacto das variáveis ao longo do tempo',
        description:
          'Este gráfico mostra os mesmos dados que acima, exibidos como linhas individuais. Suas posições a partir da linha base indicam o impacto no resultado: variáveis localizadas acima da linha base horizontal levam a uma predição mais pessimista, enquanto aquelas abaixo levam a uma predição mais otimista.',
      },
    },
  },

  methodology: {
    title: 'Metodologia',
    sidebar: {
      title: 'Sobre',
      body: [
        '<strong>A pesquisa que embasa este trabalho está disponível como um <a href="https://www.medrxiv.org/content/10.1101/2020.07.04.20146423v1.full.pdf">preprint</a> na medRxiv.</strong>',
        'Esta ferramenta é desenvolvida pela <a href="https://kunumi.com">Kunumi</a>, uma organização comprometida com o desenvolvimento da ciência e tecnologia, juntamente com o LIA-UFMG (Laboratório de Inteligência Artificial da Universidade Federal de Minas Gerais).',
        'Ela faz parte de nossos esforços para melhor entender e combater a pandemia, permitindo que tomadores de decisão, cientistas, profissionais da saúde e o público em geral entendam que fatores são mais decisivos para determinar a difusão e a letalidade do vírus. Você pode encontrar outros achados e iniciativas relacionadas à pandemia em <a href="https://www.linkedin.com/company/kunumi">nossas redes sociais</a>.',
        'Este modelo é um projeto em andamento e será constantemente atualizado assim que novos e mais confiáveis dados estejam disponíveis. Com isso, os achados retratados aqui não representam conclusões definitivas, mas sim, o início de uma conversa sobre o momento atual em que estamos vivendo no curso da pandemia. Convidamos a comunidade científica para participar: não hesite em <a href="mailto: covid-19@kunumi.com">nos contatar</a> com suas considerações sobre esta ferramenta e nos encontraremos formas para colaborar.',
      ],
    },
    intro: [
      'Os dados exibidos são resultado de predições em datas passadas feitas por um modelo de aprendizado de máquina baseado em árvore interpretável - subgrupo da inteligência artificial - que é alimentado por um número de pontos de dados e tentativas de predição de duas variáveis: ',
      '1. <strong>Velocidade</strong>, expressa pela curva de mortalidade em função do tempo (mortes/dia). Nesta ferramenta, é apresentada como Taxa de Mortalidade Diária.',
      '2. <strong>Aceleração</strong>, expressa pela curva de velocidade em função do tempo (mortes/dia²). Nesta ferramenta, é apresentada como Variação em 14 dias da Taxa de Mortalidade.',
      'No pico da epidemia no país selecionado, a ferramenta também exibe a importância de cada variável para as predições do modelo naquela data, calculadas usando os valores SHAP - Shapley Additive exPlanation  (Lundberg e Lee, 2007), um método baseado nos valores Shapley da técnica de teoria dos jogos, usada para determinar quanto contribuem os diferentes jogadores para o sucesso de um jogo colaborativo.',
      'Os valores SHAP medem quanto contribui individualmente cada variável em um modelo de aprendizado de máquina - positiva ou negativamente - para o resultado final, permitindo uma explicação refinada da predição como um todo.',
    ],
    sources: {
      title: 'Fontes de Dados',
      body: [
        'Este modelo utiliza dados das seguintes fontes: Banco Mundial, Universidade de Oxford, World Life Expectancy, Our World In Data e Universidade John Hopkins. Abaixo estão a fonte e a descrição de cada variável considerada pelo modelo.',
      ],
    },
    locations: {
      title: 'Países Estudados',
      body: [
        'Os países analisados na versão atual desta ferramenta foram selecionados numa tentativa de formar uma amostra representativa em termos de continentes, fases da pandemia e condições sócio-econômicas - dado que dados de alta qualidade e confiáveis estavam disponíveis. Mais países devem ser adicionados nas próximas semanas e sugestões são bem-vindas.',
        'Atualmente, a lista inclui: África do Sul, Alemanha, Austrália, Bélgica, Brasil, Canadá, Chile, China, Dinamarca, Egito, Espanha, Estados Unidos da América, França, Holanda, Índia, Irã, Israel, Itália, Japão, México, Nigéria, Nova Zelândia, Reino Unido e Suécia.',
      ],
    },
    training: {
      title: 'Dados De Treino',
      body: [
        'A atual versão deste modelo é treinado com dados dos seguintes países: Afeganistão, África do Sul, Alemanha, Arábia Saudita, Argélia, Argentina, Austrália, Áustria, Bangladesh, Bélgica, Bielorrússia, Bolívia, Bósnia e Herzegovina, Brasil, Bulgária, Burkina Faso, Camarões, Canadá, Catar, Chile, China, Costa Rica, Croácia, Cuba, Dinamarca, Egito, Emirados Árabes Unidos, Eslováquia, Eslovênia, Espanha, Estados Unidos da América, Estônia, Filipinas, Finlândia, França, Gana, Grécia, Guatemala, Guiana, Guiné, Haiti, Holanda, Honduras, Hungria, Índia, Indonésia, Irã, Irlanda, Israel, Itália, Japão, Jordânia, Kuwait, Letônia, Lituânia, Luxemburgo, Madagascar, Malásia, Malawi, Marrocos, México, Moldávia, Nepal, Nicarágua, Níger, Nigéria, Noruega, Nova Zelândia, Omã, Paquistão, Paraguai, Polônia, Portugal, Reino Unido, República Dominicana, Senegal, Sri Lanka, Suécia, Suíça, Suriname, Tailândia, Tajiquistão, Tanzânia, Togo, Trindade e Tobago, Tunísia e Uzbequistão.',
      ],
    },
    glossary: {
      title: 'Glossário De Variáveis',
      body: [
        'O conjunto completo de variáveis analisadas pelo modelo e seus significados estão descritos no glossário a seguir.',
      ],
    },
  },

  faq: {
    title: 'Perguntas Frequentes',
    sidebar: {
      title: 'Não Encontrou Sua Resposta?',
      body: [
        'Estas perguntas e respostas foram escolhidas com base na frequência com que eram questionadas aos nossos pesquisadores, colaboradores e pares. Nós estamos constantemente atualizando este documento com novas perguntas à medida que surgem.',
        'Se não teve sua questão respondida, por favor, <a href="mailto: covid-19@kunumi.com">contate-nos</a>.',
      ],
    },
    questions: [
      {
        question: 'Como ficam os países que não têm um pico claro?',
        answer:
          'A ferramenta exibe as variáveis que têm influência na COVID no pico de epidemia local. Em lugares sem um pico claro, como é o caso do Brasil, por exemplo, é importante ter em mente que a seleção de variáveis influentes pode mudar enquanto a epidemia estiver em um platô.',
      },
      {
        question:
          'Por que o impacto de algumas variáveis muda de reduzir para aumentar as taxas de mortalidade (e vice-versa)?',
        answer:
          'Algumas variáveis - como temperatura média - mudam seus valores ao longo do tempo, enquanto outras têm diferentes influências nos primeiros ou nos mais recentes dias da pandemia - como o percentual de idosos na população. Há também certas variáveis que podem levar dias para surtirem efeito - como campanhas de informações públicas e políticas de restrições.',
      },
      {
        question: 'Por que a importância de variáveis constantes mudam ao longo do tempo?',
        answer:
          'Há duas razões principais: a variável pode ter diferentes importâncias em diferentes momentos da curva; ou sua interação com outras variáveis dinâmicas pode influenciar sua importância a cada dia.',
      },
      {
        question:
          'Para algumas variáveis relacionadas a gênero, por que os seus equivalentes masculino ou feminino não aparecem no gráfico? Ou por que variáveis relacionadas à idade não contemplam todas as faixas ou uma faixa em específico?',
        answer:
          'O equivalente masculino ou feminino de variáveis - assim como faixas etárias - existem e foram incluídas nos dados de treinamento do modelo, mas algumas delas não estão entre as variáveis mais significativas e, por isso, não aparecem nos gráficos.',
      },
      {
        question:
          'Por que algumas variáveis contribuem para a predição de mais mortes mesmo quando seus valores aparecem melhores que a média? Da mesma forma, por que algumas variáveis contribuem para a predição de menos mortes quando têm valores piores que a média?',
        answer:
          'As médias apresentadas nos gráficos são calculadas a partir da amostra de países considerada pelo nosso modelo. Com isso, as médias apresentadas se referem aos dados dos países cujos dados foram incluídos na ferramenta, e não às médias mundiais. Além disso, variáveis próximas das médias mundiais podem ainda ter influência positiva ou negativa sobre o número de mortes.',
      },
      {
        question: 'Por que há número negativo de mortes na Espanha ao final de maio?',
        answer:
          'Em 25 de maio, a Espanha mudou a forma de coleta de dados, contabilizando as mortes pela data em que ocorreram. Com isso, ao invés de alterar os números referentes aos dias anteriormente publicados, optou-se por aplicar o saldo da diferença nesta data e aplicar a nova metodologia nos dias subsequentes. Isso afetou tanto a média móvel da mortalidade quanto o modelo. ',
      },
    ],
  },

  locations: {
    AU: 'Austrália',
    BE: 'Bélgica',
    BR: 'Brasil',
    CA: 'Canadá',
    CL: 'Chile',
    CN: 'China',
    DE: 'Alemanha',
    DK: 'Dinamarca',
    EG: 'Egito',
    ES: 'Espanha',
    FR: 'França',
    IL: 'Israel',
    IN: 'Índia',
    IR: 'Irã',
    IT: 'Itália',
    JP: 'Japão',
    MX: 'México',
    NG: 'Nigéria',
    NL: 'Holanda',
    NZ: 'Nova Zelândia',
    SE: 'Suécia',
    UK: 'Reino Unido',
    US: 'Estados Unidos',
    ZA: 'África do Sul',
  },

  baseFeature: {
    category: 'Sem categoria',
    description: 'Essa variável ainda não possui uma descrição...',
    source: '(Em revisão)',
    unit: '',
  },

  features: {
    '...rest': {
      label: '...outras variáveis',
    },
    '100-year olds (males per 100 females)': {
      label: 'Proporção entre gêneros - faixa de 100 anos',
      category: 'Demografia',
      source: 'Our World in Data',
      unit: 'homens/100 mulheres',
      description: 'A proporção de gênero (ou proporção de sexo) é a proporção de homens e mulheres em uma determinada população, geralmente expressa como o número de homens por 100 mulheres',
      url: 'https://ourworldindata.org/gender-ratio',
    },
    '40-year olds (males per 100 females)': {
      label: 'Proporção entre gêneros - faixa de 40 anos',
      category: 'Demografia',
      source: 'Our World in Data',
      unit: 'homens/100 mulheres',
      description: 'A proporção de gênero (ou proporção de sexo) é a proporção de homens e mulheres em uma determinada população, geralmente expressa como o número de homens por 100 mulheres',
      url: 'https://ourworldindata.org/gender-ratio',
    },
    '50-year olds (males per 100 females)': {
      label: 'Proporção entre gêneros - faixa de 50 anos',
      category: 'Demografia',
      source: 'Our World in Data',
      unit: 'homens/100 mulheres',
      description: 'A proporção de gênero (ou proporção de sexo) é a proporção de homens e mulheres em uma determinada população, geralmente expressa como o número de homens por 100 mulheres',
      url: 'https://ourworldindata.org/gender-ratio',
    },
    '60-year olds (males per 100 females)': {
      label: 'Proporção entre gêneros - faixa de 60 anos',
      category: 'Demografia',
      source: 'Our World in Data',
      unit: 'homens/100 mulheres',
      description: 'A proporção de gênero (ou proporção de sexo) é a proporção de homens e mulheres em uma determinada população, geralmente expressa como o número de homens por 100 mulheres',
      url: 'https://ourworldindata.org/gender-ratio',
    },
    '70-year olds (males per 100 females)': {
      label: 'Proporção entre gêneros - faixa de 70 anos',
      category: 'Demografia',
      source: 'Our World in Data',
      unit: 'homens/100 mulheres',
      description: 'A proporção de gênero (ou proporção de sexo) é a proporção de homens e mulheres em uma determinada população, geralmente expressa como o número de homens por 100 mulheres',
      url: 'https://ourworldindata.org/gender-ratio',
    },
    '80-year olds (males per 100 females)': {
      label: 'Proporção entre gêneros - faixa de 80 anos',
      category: 'Demografia',
      source: 'Our World in Data',
      unit: 'homens/100 mulheres',
      description: 'A proporção de gênero (ou proporção de sexo) é a proporção de homens e mulheres em uma determinada população, geralmente expressa como o número de homens por 100 mulheres',
      url: 'https://ourworldindata.org/gender-ratio',
    },
    '90-year olds (males per 100 females)': {
      label: 'Proporção entre gêneros - faixa de 90 anos',
      category: 'Demografia',
      source: 'Our World in Data',
      unit: 'homens/100 mulheres',
      description: 'A proporção de gênero (ou proporção de sexo) é a proporção de homens e mulheres em uma determinada população, geralmente expressa como o número de homens por 100 mulheres',
      url: 'https://ourworldindata.org/gender-ratio',
    },
    'Agglomerations of +1 million (% of urban population)': {
      label: 'População em aglomerações urbanas de mais de 1 milhão',
      category: 'Demografia',
      source: 'Banco Mundial',
      unit: '% da pop.',
      description: 'População em aglomerações urbanas de mais um milhão é o percentual da população de um país que vive em áreas metropolitanas que em 2016 tinha uma população de mais de um milhão de pessoas.',
      url: 'https://data.worldbank.org/indicator/en.urb.mcty.tl.zs',
    },
    'Average Temperature (C)': {
      label: 'Temperatura média',
      category: 'Clima',
      source: 'Centro Nacional para Informações Ambientais (NCEI)',
      unit: '(°C) Celsius',
      description: 'Média das temperaturas medidas durante um dia. Faltantes = 9999.9. Dados suavizados usando média móvel de 7 dias e utilizamos um deslocamento de três semanas para melhor correlação com o número de mortes, levando em consideração uma aproximação dos períodos de incubação e tratamento.',
      url: 'https://www.ncei.noaa.gov/access/search/data-search/global-summary-of-the-day',
    },
    'BCG coverage (%)': {
      label: 'Cobertura de vacinação contra BCG',
      category: 'Saúde',
      source: 'BCG World Atlas',
      unit: '% da pop.',
      description: 'A vacina BCG protege contra a tuberculose, também conhecida como TB. A tuberculose é uma infecção grave que afeta os pulmões e, às vezes, outras partes do corpo, como ossos, articulações e rins. Dado se refere a porcentagem da população que recebeu uma dose da vacina bacille Calmette-Guérin (BCG).',
      url: 'http://www.bcgatlas.org/index.php',
    },
    'BCG vaccination started (years)': {
      label: 'Anos c/ vacinação contra BCG',
      category: 'Saúde',
      source: 'BCG World Atlas',
      unit: 'anos',
      description: 'Ano em que o país iniciou vacinação ampla com BCG',
      url: 'http://www.bcgatlas.org/index.php',
    },
    'BCG vaccination stopped (years)': {
      label: 'Anos s/ vacinação contra BCG',
      category: 'Saúde',
      source: 'BCG World Atlas',
      unit: 'anos',
      description: 'Ano em que o país interrompeu vacinação ampla com BCG',
      url: 'http://www.bcgatlas.org/index.php',
    },
    'Contact tracing - days with no contact tracing': {
      label: 'Dias s/ rastreamento de contatos pelo governo',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'dias',
      description: 'Governos que estão rastreando contatos',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Critical care beds needed/available': {
      label: 'Proporção de leitos de UTI necessários por disponíveis',
      category: 'Saúde',
      source: 'Instituto de Métricas e Avaliação em Saúde (IHME)',
      unit: 'proporção',
      description: 'Proporção calculada de camas de cuidados intensivos necessários e camas de cuidados intensivos disponíveis',
      url: 'http://www.healthdata.org/covid',
    },
    'Current BCG vaccination': {
      label: 'Programa de vacinação contra BCG ativa',
      category: 'Saúde',
      source: 'BCG World Atlas',
      unit: 'Binário (sim ou não)',
      description: 'Registro se o país possui um programa de vacinação BCG em vigor',
      url: 'http://www.bcgatlas.org/index.php',
    },
    'Current health expenditure (% of GDP)': {
      label: 'Gasto corrente em saúde',
      category: 'Saúde',
      source: 'Banco Mundial',
      unit: '% do PIB',
      description: 'Nível de gasto atual com saúde como percentual do Produto Interno Bruto. As estimativas dos gastos atuais com saúde incluem bens e serviços de saúde consumidos durante cada ano. Este indicador não inclui gastos de saúde como edifícios, máquinas, TI e estoques de vacinas para emergências ou surtos',
      url: 'https://data.worldbank.org/indicator/SH.XPD.CHEX.GD.ZS',
    },
    'Days of outbreak': {
      label: 'Dias após o 1o caso',
      category: 'Temporal',
      source: 'Interno',
      unit: 'dias',
      description: 'Número de dias após o primeiro caso confirmado',
      url: null,
    },
    'Death rate from CVDs (per 100k)': {
      label: 'Mortalidade por doenças cardiovasculares',
      category: 'Saúde',
      source: 'Our World in Data',
      unit: '/100 mil',
      description: 'Doença cardiovascular (DCV) é um termo usado para se referir à variedade de doenças que afetam o coração e os vasos sanguíneos. Estes incluem hipertensão (pressão alta); doença cardíaca coronária (ataque cardíaco); doença cerebrovascular (acidente vascular cerebral); insuficiência cardíaca; e outras doenças cardíacas',
      url: 'https://ourworldindata.org/causes-of-death',
    },
    'Deaths from AIDS female (world avg)': {
      label: 'Mortalidade por AIDS - mulheres',
      category: 'Saúde',
      source: 'World life expectancy',
      unit: 'razão relativa à média global',
      description: 'CID-10 CÓDIGOS: B20-B24\nDados arredondados. Os decimais afetam a classificação.\nFonte de dados: ORGANIZAÇÃO MUNDIAL DA SAÚDE\nEstimativas de taxa de mortalidade ajustadas por idade: 2017\nCálculos fornecidos pelo World Life Expectancy\nPrecisão dos dados não garantida\nA OMS exclui dados de causa de morte Nos seguintes países: Andorra, Ilhas Cook, Dominica, Ilhas Marshall, Mônaco, Nauru, Niue, Palau, São Cristóvão, São Marinho, Tuvalu',
      url: 'https://www.worldlifeexpectancy.com/cause-of-death/hiv-aids/by-country/',
    },
    'Deaths from AIDS male (world avg)': {
      label: 'Mortalidade por AIDS - homens',
      category: 'Saúde',
      source: 'World life expectancy',
      unit: 'razão relativa à média global',
      description: 'CID-10 CÓDIGOS: B20-B24\nDados arredondados. Os decimais afetam a classificação.\nFonte de dados: ORGANIZAÇÃO MUNDIAL DA SAÚDE\nEstimativas de taxa de mortalidade ajustadas por idade: 2017\nCálculos fornecidos pelo World Life Expectancy\nPrecisão dos dados não garantida\nA OMS exclui dados de causa de morte Nos seguintes países: Andorra, Ilhas Cook, Dominica, Ilhas Marshall, Mônaco, Nauru, Niue, Palau, São Cristóvão, São Marinho, Tuvalu',
      url: 'https://www.worldlifeexpectancy.com/cause-of-death/hiv-aids/by-country/',
    },
    'Deaths from Cancer 15-49 years (world avg)': {
      label: 'Mortalidade por Cancer - 15-49 anos',
      category: 'Saúde',
      source: 'Our World in Data',
      unit: 'razão relativa à média global',
      description: 'Câncer é um termo para doenças nas quais células anormais se dividem sem controle e podem invadir tecidos próximos. As células cancerígenas também podem se espalhar para outras partes do corpo através dos sistemas sanguíneo e linfático. Existem vários tipos de câncer.',
      url: 'https://ourworldindata.org/grapher/cancer-deaths-by-age?stackMode=relative',
    },
    'Deaths from Cancer 50-69 years (world avg)': {
      label: 'Mortalidade por Cancer - 50-69 anos',
      category: 'Saúde',
      source: 'Our World in Data',
      unit: 'razão relativa à média global',
      description: 'Câncer é um termo para doenças nas quais células anormais se dividem sem controle e podem invadir tecidos próximos. As células cancerígenas também podem se espalhar para outras partes do corpo através dos sistemas sanguíneo e linfático. Existem vários tipos de câncer.',
      url: 'https://ourworldindata.org/grapher/cancer-deaths-by-age?stackMode=relative',
    },
    'Deaths from Cancer 70+ years (world avg)': {
      label: 'Mortalidade por Cancer - 70+ anos',
      category: 'Saúde',
      source: 'Our World in Data',
      unit: 'razão relativa à média global',
      description: 'Câncer é um termo para doenças nas quais células anormais se dividem sem controle e podem invadir tecidos próximos. As células cancerígenas também podem se espalhar para outras partes do corpo através dos sistemas sanguíneo e linfático. Existem vários tipos de câncer.',
      url: 'https://ourworldindata.org/grapher/cancer-deaths-by-age?stackMode=relative',
    },
    'Deaths from Cancer female (world avg)': {
      label: 'Mortalidade por Cancer - mulheres',
      category: 'Saúde',
      source: 'World life expectancy',
      unit: 'razão relativa à média global',
      description: 'Câncer é um termo para doenças nas quais células anormais se dividem sem controle e podem invadir tecidos próximos. As células cancerígenas também podem se espalhar para outras partes do corpo através dos sistemas sanguíneo e linfático. Existem vários tipos de câncer.',
      url: 'https://www.worldlifeexpectancy.com/cause-of-death/asthma/by-country/',
    },
    'Deaths from Cancer male (world avg)': {
      label: 'Mortalidade por cancer - homens',
      category: 'Saúde',
      source: 'World life expectancy',
      unit: 'razão relativa à média global',
      description: 'Câncer é um termo para doenças nas quais células anormais se dividem sem controle e podem invadir tecidos próximos. As células cancerígenas também podem se espalhar para outras partes do corpo através dos sistemas sanguíneo e linfático. Existem vários tipos de câncer.',
      url: 'https://www.worldlifeexpectancy.com/cause-of-death/asthma/by-country/',
    },
    'Deaths from CVDs 15-49 years (world avg)': {
      label: 'Mortalidade por Doenças Cardiovasculares - 15-49 anos',
      category: 'Saúde',
      source: 'Our World in Data',
      unit: 'razão relativa à média global',
      description: 'Doença cardiovascular (DCV) é um termo usado para se referir à variedade de doenças que afetam o coração e os vasos sanguíneos. Estes incluem hipertensão (pressão alta); doença cardíaca coronária (ataque cardíaco); doença cerebrovascular (acidente vascular cerebral); insuficiência cardíaca; e outras doenças cardíacas',
      url: 'https://ourworldindata.org/grapher/cardiovascular-disease-death-rates-by-age',
    },
    'Deaths from CVDs 50-69 years (world avg)': {
      label: 'Mortalidade por Doenças Cardiovasculares - 50-69 anos',
      category: 'Saúde',
      source: 'Our World in Data',
      unit: 'razão relativa à média global',
      description: 'Doença cardiovascular (DCV) é um termo usado para se referir à variedade de doenças que afetam o coração e os vasos sanguíneos. Estes incluem hipertensão (pressão alta); doença cardíaca coronária (ataque cardíaco); doença cerebrovascular (acidente vascular cerebral); insuficiência cardíaca; e outras doenças cardíacas',
      url: 'https://ourworldindata.org/grapher/cardiovascular-disease-death-rates-by-age',
    },
    'Deaths from CVDs 70+ years (world avg)': {
      label: 'Mortalidade por Doenças Cardiovasculares - 70+ anos',
      category: 'Saúde',
      source: 'Our World in Data',
      unit: 'razão relativa à média global',
      description: 'Doença cardiovascular (DCV) é um termo usado para se referir à variedade de doenças que afetam o coração e os vasos sanguíneos. Estes incluem hipertensão (pressão alta); doença cardíaca coronária (ataque cardíaco); doença cerebrovascular (acidente vascular cerebral); insuficiência cardíaca; e outras doenças cardíacas',
      url: 'https://ourworldindata.org/grapher/cardiovascular-disease-death-rates-by-age',
    },
    'Deaths from Diabetes 15-49 years (world avg)': {
      label: 'Mortalidade por Diabetes - 15-49 anos',
      category: 'Saúde',
      source: 'Our World in Data',
      unit: 'razão relativa à média global',
      description: 'O diabetes é uma doença que ocorre quando a glicose no sangue, também chamada de açúcar no sangue, é muito alta. A glicose no sangue é sua principal fonte de energia e vem dos alimentos que se come. A insulina, um hormônio produzido pelo pâncreas, ajuda a glicose dos alimentos a entrar nas células para serem usados como energia. Às vezes, o corpo não produz insulina suficiente, nenhuma insulina ou não usa insulina adequadamente. A glicose fica no sangue e não atinge as células.',
      url: 'https://ourworldindata.org/grapher/causes-of-death-in-15-49-year-olds',
    },
    'Deaths from Diabetes 70+ years (world avg)': {
      label: 'Mortalidade por Diabetes - 70+ anos',
      category: 'Saúde',
      source: 'Our World in Data',
      unit: 'razão relativa à média global',
      description: 'O diabetes é uma doença que ocorre quando a glicose no sangue, também chamada de açúcar no sangue, é muito alta. A glicose no sangue é sua principal fonte de energia e vem dos alimentos que se come. A insulina, um hormônio produzido pelo pâncreas, ajuda a glicose dos alimentos a entrar nas células para serem usados como energia. Às vezes, o corpo não produz insulina suficiente, nenhuma insulina ou não usa insulina adequadamente. A glicose fica no sangue e não atinge as células.',
      url: 'https://ourworldindata.org/grapher/causes-of-death-in-70-year-olds',
    },
    'Deaths from Diabetes female (world avg)': {
      label: 'Mortalidade por Diabetes Mellitus - mulheres',
      category: 'Saúde',
      source: 'World life expectancy',
      unit: 'razão relativa à média global',
      description: 'CID-10 CÓDIGOS: E10-E14 (MENOS E10.2-E10.29, E11.2-E11.29, E12.2, E13.2-E13.29, E14.2)\nDados arredondados. Os decimais afetam a classificação.\nFonte de dados: ORGANIZAÇÃO MUNDIAL DA SAÚDE\nEstimativas de taxa de mortalidade ajustadas por idade: 2017\nCálculos fornecidos pelo World Life Expectancy\nPrecisão dos dados não garantida\nA OMS exclui dados de causa de morte Nos seguintes países: Andorra, Ilhas Cook, Dominica, Ilhas Marshall, Mônaco, Nauru, Niue, Palau, São Cristóvão, São Marinho, Tuvalu',
      url: 'https://www.worldlifeexpectancy.com/cause-of-death/diabetes-mellitus/by-country/',
    },
    'Deaths from Diabetes male (world avg)': {
      label: 'Mortalidade por Diabetes Mellitus - homens',
      category: 'Saúde',
      source: 'World life expectancy',
      unit: 'razão relativa à média global',
      description: 'CID-10 CÓDIGOS: E10-E14 (menos E10.2-E10.29, E11.2-E11.29, E12.2, E13.2-E13.29, E14.2)\nDados arredondados. Os decimais afetam a classificação.\nFonte de dados: ORGANIZAÇÃO MUNDIAL DA SAÚDE\nEstimativas de taxa de mortalidade ajustadas por idade: 2017\nCálculos fornecidos pelo World Life Expectancy\nPrecisão dos dados não garantida\nA OMS exclui dados de causa de morte Nos seguintes países: Andorra, Ilhas Cook, Dominica, Ilhas Marshall, Mônaco, Nauru, Niue, Palau, São Cristóvão, São Marinho, Tuvalu',
      url: 'https://www.worldlifeexpectancy.com/cause-of-death/diabetes-mellitus/by-country/',
    },
    'Deaths from Stroke female (world avg)': {
      label: 'Mortalidade por Infarto - mulheres',
      category: 'Saúde',
      source: 'World life expectancy',
      unit: 'razão relativa à média global',
      description: 'CID-10 CÓDIGOS: I60-I69\nDados arredondados. Os decimais afetam a classificação.\nFonte de dados: ORGANIZAÇÃO MUNDIAL DA SAÚDE\nEstimativas de taxa de mortalidade ajustadas por idade: 2017\nCálculos fornecidos pelo World Life Expectancy\nPrecisão dos dados não garantida\nA OMS exclui dados de causa de morte Nos seguintes países: Andorra, Ilhas Cook, Dominica, Ilhas Marshall, Mônaco, Nauru, Niue, Palau, São Cristóvão, São Marinho, Tuvalu',
      url: 'https://www.worldlifeexpectancy.com/cause-of-death/stroke/by-country/',
    },
    'Deaths from Stroke male (world avg)': {
      label: 'Mortalidade por Infarto - homens',
      category: 'Saúde',
      source: 'World life expectancy',
      unit: 'razão relativa à média global',
      description: 'CID-10 CÓDIGOS: I60-I69\nDados arredondados. Os decimais afetam a classificação.\nFonte de dados: ORGANIZAÇÃO MUNDIAL DA SAÚDE\nEstimativas de taxa de mortalidade ajustadas por idade: 2017\nCálculos fornecidos pelo World Life Expectancy\nPrecisão dos dados não garantida\nA OMS exclui dados de causa de morte Nos seguintes países: Andorra, Ilhas Cook, Dominica, Ilhas Marshall, Mônaco, Nauru, Niue, Palau, São Cristóvão, São Marinho, Tuvalu',
      url: 'https://www.worldlifeexpectancy.com/cause-of-death/stroke/by-country/',
    },
    'Deaths of 15 to 49 years old (% of deaths)': {
      label: 'Mortes - faixa etária 15-49',
      category: 'Demografia',
      source: 'Our World in Data',
      unit: '% de mortes',
      description: 'Proporção de mortes para a faixa etária como porcentagem do número total de mortes. A morte é definida como a cessação de todas as funções vitais do corpo, incluindo batimentos cardíacos, atividade cerebral (incluindo o tronco cerebral) e respiração',
      url: 'https://ourworldindata.org/grapher/number-of-deaths-by-age-group?stackMode=relative',
    },
    'Deaths of 50 to 69 years old (% of deaths)': {
      label: 'Mortes - faixa etária 50-69',
      category: 'Demografia',
      source: 'Our World in Data',
      unit: '% de mortes',
      description: 'Proporção de mortes para a faixa etária como porcentagem do número total de mortes. A morte é definida como a cessação de todas as funções vitais do corpo, incluindo batimentos cardíacos, atividade cerebral (incluindo o tronco cerebral) e respiração',
      url: 'https://ourworldindata.org/grapher/number-of-deaths-by-age-group?stackMode=relative',
    },
    'Deaths of 70+ years old (% of deaths)': {
      label: 'Mortes - faixa etária 70+',
      category: 'Demografia',
      source: 'Our World in Data',
      unit: '% de mortes',
      description: 'Proporção de mortes para a faixa etária como porcentagem do número total de mortes. A morte é definida como a cessação de todas as funções vitais do corpo, incluindo batimentos cardíacos, atividade cerebral (incluindo o tronco cerebral) e respiração',
      url: 'https://ourworldindata.org/grapher/number-of-deaths-by-age-group?stackMode=relative',
    },
    'Debt/contract relief - broad debt/contract relief': {
      label: 'Dias c/ maior alívio de obrig. financeiras residenciais',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'dias',
      description: 'Registro se governo postergou pagamentos de obrigações financeiras da população (por exemplo, postergar o pagamento de empréstimos, impedir cortes de serviços públicos como água ou proibir despejos)',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Debt/contract relief - days with no debt/contract relief': {
      label: 'Dias s/ alívio de obrig. financeiras residenciais',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'dias',
      description: 'Registro se governo postergou pagamentos de obrigações financeiras da população (por exemplo, postergar o pagamento de empréstimos, impedir cortes de serviços públicos como água ou proibir despejos)',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Debt/contract relief - narrow relief': {
      label: 'Dias c/ curto alívio de obrig. financeiras residenciais',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'dias',
      description: 'Registro se governo postergou pagamentos de obrigações financeiras da população (por exemplo, postergar o pagamento de empréstimos, impedir cortes de serviços públicos como água ou proibir despejos)',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Dew Point (C)': {
      label: 'Ponto de condensação',
      category: 'Clima',
      source: 'Centro Nacional para Informações Ambientais (NCEI)',
      unit: '(°C) Celsius',
      description: 'Ponto de condensação médio para o dia. Faltantes = 9999.9. Dados suavizados usando média móvel de 7 dias e utilizamos um deslocamento de três semanas para melhor correlação com o número de mortes, levando em consideração uma aproximação dos períodos de incubação e tratamento.',
      url: 'https://www.ncei.noaa.gov/access/search/data-search/global-summary-of-the-day',
    },
    'Diabetes prevalence (% of population)': {
      label: 'Prevalência de diabetes',
      category: 'Saúde',
      source: 'World Bank',
      unit: '% da pop.',
      description: 'A prevalência de diabetes refere-se à porcentagem de pessoas de todas as idades que têm diabetes tipo 1 ou tipo 2',
      url: 'https://data.worldbank.org/indicator/SH.STA.DIAB.ZS',
    },
    'Facial coverings - days with no policy': {
      label: 'Uso de máscaras (s/ medidas)',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'dias',
      description: 'Registro da política de uso de máscara facial',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Facial coverings - recommended': {
      label: 'Uso de máscaras (recomendação)',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'dias',
      description: 'Registro da política de uso de máscara facial',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Facial coverings - required in all shared/public places in all situations': {
      label: 'Uso de máscaras (obrigação em todos locais públicos)',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'dias',
      description: 'Registro da política de uso de máscara facial',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Facial coverings - required in some shared/public places in some situations': {
      label: 'Uso de máscaras (obrigação em alguns locais públicos)',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'dias',
      description: 'Registro da política de uso de máscara facial',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Facial coverings - required outside home at all times': {
      label: 'Uso de máscaras (obrigação fora de casa)',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'dias',
      description: 'Registro da política de uso de máscara facial',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Income - days with no income support': {
      label: 'Dias s/ suporte de renda',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'dias',
      description: 'Registro se o governo está cobrindo os salários ou fornecendo pagamentos diretos em dinheiro, renda básica universal ou similar, de pessoas que perdem o emprego ou não podem trabalhar. (Inclui pagamentos a empresas se explicitamente vinculados a folha de pagamento/salários)',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Income distribution (GINI Index)': {
      label: 'Índice de Gini',
      category: 'Economia',
      source: 'Banco Mundial',
      unit: 'pontos',
      description: 'Mede até que ponto a distribuição de renda entre indivíduos ou famílias em uma economia se desvia de uma distribuição perfeitamente igual\n0 = igualdade perfeita\n100 = desigualdade perfeita',
      url: 'https://data.worldbank.org/indicator/SI.POV.GINI',
    },
    'Internal movement - days with no measures': {
      label: 'Dias s/ medidas de restrição na movimentação interna',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'dias',
      description: 'Registro de restrições ao movimento interno no país',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Internal movement - recommend not to travel': {
      label: 'Dias c/ recomendação de restrições na movim. interna',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'dias',
      description: 'Registro de restrições ao movimento interno no país',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'International travel - ban arrivals from some regions': {
      label: 'Dias c/ proibição - chegadas internac de áreas de risco',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'dias',
      description: 'Registro de restrições em viagens internacionais',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'International travel - ban on all regions': {
      label: 'Dias c/ proibição total - chegadas internacionais',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'dias',
      description: 'Registro de restrições em viagens internacionais',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'International travel - days with no restrictions': {
      label: 'Dias s/ restrições - chegadas internacionais',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'dias',
      description: 'Registro de restrições em viagens internacionais',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'International travel - quarantine arrivals': {
      label: 'Dias c/ quarentena-chegadas internac. de áreas de risco',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'dias',
      description: 'Registro de restrições em viagens internacionais',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'International travel - screening arrivals': {
      label: 'Dias c/ triagem - chegada de viagens internacionais',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'dias',
      description: 'Registro de restrições em viagens internacionais',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Long-term care beds (per 100k aged 70+)': {
      label: 'Camas de cuidados prolongados p/ 70+ anos',
      category: 'Saúde',
      source: 'Instituto de Métricas e Avaliação em Saúde (IHME)',
      unit: '/100 mil 70+',
      description: 'Camas de cuidados prolongados em hospitais são camas hospitalares que acomodam pacientes que necessitam de cuidados prolongados devido a deficiências crônicas e um grau reduzido de independência nas atividades da vida diária',
      url: 'http://www.healthdata.org/covid',
    },
    'Maximum Temperature (C)': {
      label: 'Temperatura máxima',
      category: 'Clima',
      source: 'Centro Nacional para Informações Ambientais (NCEI)',
      unit: '(°C) Celsius',
      description: 'Temperatura máxima medida durante um dia. Horário da medição pode variar entre países e regiões, portanto essa pode não ser a máxima do dia do calendário. Faltantes = 9999.9. Dados suavizados usando média móvel de 7 dias e utilizamos um deslocamento de três semanas para melhor correlação com o número de mortes, levando em consideração uma aproximação dos períodos de incubação e tratamento.',
      url: 'https://www.ncei.noaa.gov/access/search/data-search/global-summary-of-the-day',
    },
    'Minimum Temperature (C)': {
      label: 'Temperatura mínima',
      category: 'Clima',
      source: 'Centro Nacional para Informações Ambientais (NCEI)',
      unit: '(°C) Celsius',
      description: 'Temperatura mínima medida durante um dia. Horário da medição pode variar entre países e regiões, portanto essa pode não ser a mínima do dia do calendário. Faltantes = 9999.9. Dados suavizados usando média móvel de 7 dias e utilizamos um deslocamento de três semanas para melhor correlação com o número de mortes, levando em consideração uma aproximação dos períodos de incubação e tratamento.',
      url: 'https://www.ncei.noaa.gov/access/search/data-search/global-summary-of-the-day',
    },
    'Population density (inhabitants per Km2)': {
      label: 'Densidade populacional',
      category: 'Demografia',
      source: 'Banco Mundial',
      unit: 'pessoas/km² do território',
      description: 'Para os seres humanos, a densidade populacional é o número de pessoas por unidade de área, geralmente citadas por quilômetro quadrado ou milha quadrada, e que pode incluir ou excluir, por exemplo, áreas de água ou geleiras.',
      url: 'https://data.worldbank.org/indicator/EN.POP.DNST',
    },
    'Population in the largest city (% urban population)': {
      label: 'Pop. nas maiores cidades',
      category: 'Demografia',
      source: 'Banco Mundial',
      unit: '% da pop. urbana',
      description: 'População na maior cidade é a porcentagem da população urbana de um país que vive na maior área metropolitana do país',
      url: 'https://data.worldbank.org/indicator/EN.URB.LCTY.UR.ZS',
    },
    'Population living in slums (% urban population)': {
      label: 'População vivendo em favelas',
      category: 'Demografia',
      source: 'Banco Mundial',
      unit: '% da pop. urbana',
      description: 'A população que vive em favelas é a proporção da população urbana que vive em domicílios de favelas. Um domicílio de favela é definido como um grupo de indivíduos que vivem sob o mesmo teto, sem uma ou mais das seguintes condições: acesso a água tratada, acesso a saneamento tratado, área útil suficiente e durabilidade da moradia.',
      url: 'https://data.worldbank.org/indicator/en.pop.slum.ur.zs',
    },
    Precipitation: {
      label: 'Precipitação',
      category: 'Clima',
      source: 'Centro Nacional para Informações Ambientais (NCEI)',
      unit: 'centésimos de polegadas',
      description: 'Precipitação total (chuva e/ou derretimento de neve) medidos durante o dia; normalmente não termina meia-noite, ou seja, pode incluir parte final do dia anterior. Dados suavizados usando média móvel de 7 dias e utilizamos um deslocamento de três semanas para melhor correlação com o número de mortes, levando em consideração uma aproximação dos períodos de incubação e tratamento.',
      url: 'https://www.ncei.noaa.gov/access/search/data-search/global-summary-of-the-day',
    },
    'Private gatherings - 10 or less people': {
      label: 'Dias c/ restrições na reunião de menos de 10 pessoas',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'dias',
      description: 'Registro de limite de participante em reuniões privadas',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Private gatherings - 101 to 1000 people': {
      label: 'Dias c/ restrições na reunião entre 100 e 1 mil pessoas',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'dias',
      description: 'Registro de limite de participante em reuniões privadas',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Private gatherings - 11 to 100 people': {
      label: 'Dias c/ restrições na reunião entre 10 e 100 pessoas',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'dias',
      description: 'Registro de limite de participante em reuniões privadas',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Private gatherings - above 1000 people': {
      label: 'Dias c/ restrições na reunião de mais de 1 mil pessoas',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'dias',
      description: 'Registro de limite de participante em reuniões privadas',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Private gatherings - days with no restrictions': {
      label: 'Dias s/ medidas de restrições na reunião de pessoas',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'dias',
      description: 'Registro de limite de participante em reuniões privadas',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Public events - days with no measures': {
      label: 'Dias s/ medidas de cancelamento de eventos públicos',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'dias',
      description: 'Registro de cancelamento de eventos públicos',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Public events - recommend cancelling': {
      label: 'Dias c/ recomend. de cancelamento de eventos públicos',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'dias',
      description: 'Registro de cancelamento de eventos públicos',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Public events - require cancelling': {
      label: 'Dias c/ exigência de cancelamento de eventos públicos',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'dias',
      description: 'Registro de cancelamento de eventos públicos',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Public info campaigns - days with no campaign': {
      label: 'Dias s/ medidas em campanhas de informação pública',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'dias',
      description: 'Registro de presença de campanhas de informação públicas',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Public info campaigns - officials urging caution': {
      label: 'Dias c/ campanhas de info. pública-exigência de cuidado',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'dias',
      description: 'Registro de presença de campanhas de informação públicas',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Public transport - days with no measures': {
      label: 'Dias s/ medidas de fechamento de transporte público',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'dias',
      description: 'Registro de fechamento/interrupção de transporte público',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Public transport - recommend closing': {
      label: 'Dias c/ disponibilidade reduzida de transporte público',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'dias',
      description: 'Registro de fechamento/interrupção de transporte público',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Public transport - require closing': {
      label: 'Dias c/ exigência de fechamento de transporte público',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'dias',
      description: 'Registro de fechamento/interrupção de transporte público',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Residential percent change from baseline': {
      label: 'Mobilidade: áreas residenciais',
      category: 'Mobilidade',
      source: 'Google',
      unit: '% de variação sobre a referência',
      description: 'Tendências de mobilidade de áreas residenciais. Estes conjuntos de dados mostram como as visitas e o tempo de permanência em locais diferentes mudam em comparação com um valor base. Utilizamos um deslocamento de três semanas para melhor correlação com o número de mortes, levando em consideração uma aproximação dos períodos de incubação e tratamento.',
      url: 'https://www.google.com/covid19/mobility/',
    },
    'Schools - days with no measures': {
      label: 'Dias s/ medidas p/ fechamento de escolas/univers.',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'dias',
      description: 'Registro de fechamento de escolas e universidades. Fechamento de escolas: em alguns lugares, todas as escolas foram fechadas; em outros, universidades fechadas em uma janela de tempo diferente das escolas primárias; em outros lugares ainda, as escolas permanecem abertas apenas para os filhos de trabalhadores essenciais\nEscala ordinal + binário para escopo geográfico',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Schools - recommend closing': {
      label: 'Dias c/ recomendação p/ fechamento de escolas/univers.',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'dias',
      description: 'Registro de fechamento de escolas e universidades. Fechamento de escolas: em alguns lugares, todas as escolas foram fechadas; em outros, universidades fechadas em uma janela de tempo diferente das escolas primárias; em outros lugares ainda, as escolas permanecem abertas apenas para os filhos de trabalhadores essenciais\nEscala ordinal + binário para escopo geográfico',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Schools - require closing (all levels)': {
      label: 'Dias c/ fech. exigido de escolas/univers.-todos níveis',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'dias',
      description: 'Registro de fechamento de escolas e universidades. Fechamento de escolas: em alguns lugares, todas as escolas foram fechadas; em outros, universidades fechadas em uma janela de tempo diferente das escolas primárias; em outros lugares ainda, as escolas permanecem abertas apenas para os filhos de trabalhadores essenciais\nEscala ordinal + binário para escopo geográfico',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Schools - require closing (only some levels)': {
      label: 'Dias c/ fech. exigido de escolas/univers.-alguns níveis',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'dias',
      description: 'Registro de fechamento de escolas e universidades. Fechamento de escolas: em alguns lugares, todas as escolas foram fechadas; em outros, universidades fechadas em uma janela de tempo diferente das escolas primárias; em outros lugares ainda, as escolas permanecem abertas apenas para os filhos de trabalhadores essenciais\nEscala ordinal + binário para escopo geográfico',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Share infected with HIV (% of population)': {
      label: '% da população infectada por HIV',
      category: 'Saúde',
      source: 'Our World in Data',
      unit: '% da pop.',
      description: 'A prevalência de HIV refere-se à porcentagem de pessoas infectadas pelo HIV na população total, de todas as idades',
      url: 'https://ourworldindata.org/grapher/share-of-population-infected-with-hiv-ihme',
    },
    'Share of men who are obese (% male population)': {
      label: 'Obesidade masculina',
      category: 'Saúde',
      source: 'Organização Mundial da Saúde',
      unit: '% da pop. de homens',
      description: 'Percentual de homens com 18 anos ou mais com Índice de Massa Corporal (IMC) acima de 30',
      url: 'https://apps.who.int/gho/data/view.main.CTRY2450A?lang=en',
    },
    'Share of men who smoke (% male population)': {
      label: 'Homens fumantes',
      category: 'Saúde',
      source: 'Banco Mundial',
      unit: '% da pop. de homens',
      description: 'A prevalência de tabagismo no sexo masculino é a porcentagem de homens com 15 anos ou mais que atualmente fumam qualquer produto de tabaco diariamente ou não diariamente. Exclui o uso de tabaco sem fumaça. As taxas são padronizadas por idade.',
      url: 'https://data.worldbank.org/indicator/sh.prv.smok.ma',
    },
    'Share of women who are obese (% female population)': {
      label: 'Obesidade feminina',
      category: 'Saúde',
      source: 'Organização Mundial da Saúde',
      unit: '% da pop. de mulheres',
      description: 'Percentual de mulheres com 18 anos ou mais com Índice de Massa Corporal (IMC) acima de 30',
      url: 'https://apps.who.int/gho/data/view.main.CTRY2450A?lang=en',
    },
    'Share of women who smoke (% female population)': {
      label: 'Mulheres fumantes',
      category: 'Saúde',
      source: 'Banco Mundial',
      unit: '% da pop. de mulheres',
      description: 'A prevalência de tabagismo no sexo feminino é a porcentagem de mulheres com 15 anos ou mais que atualmente fumam qualquer produto de tabaco diariamente ou não diariamente. Exclui o uso de tabaco sem fumaça. As taxas são padronizadas por idade.',
      url: 'https://data.worldbank.org/indicator/sh.prv.smok.fe',
    },
    'Share that is 70 years and older (% of population)': {
      label: '% da população c/ 70+ anos',
      category: 'Demografia',
      source: 'Our World in Data',
      unit: '% da pop.',
      description: 'População com mais de 70 anos de idade, ambos os sexos',
      url: 'https://ourworldindata.org/grapher/share-of-the-population-that-is-70-years-and-older',
    },
    'Shelter-in-place - days with no measures': {
      label: 'Dias s/ medidas p/ permanecer em casa',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'dias',
      description: 'Registro de pedidos oficiais para o confinamento em casa',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Shelter-in-place - recommend not leaving house': {
      label: 'Dias c/ recomendação p/ não sair de casa',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'dias',
      description: 'Registro de pedidos oficiais para o confinamento em casa',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Shelter-in-place - require not leaving house (with exceptions)': {
      label: 'Dias c/ exigência p/ permanecer em casa (c/ exceções)',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'dias',
      description: 'Registro de pedidos oficiais para o confinamento em casa',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Shelter-in-place - require not leaving house (with minimal exceptions)': {
      label: 'Dias c/ exigência p/ permanecer em casa (exceções mín.)',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'dias',
      description: 'Registro de pedidos oficiais para o confinamento em casa',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Testing policy - anyone with symptoms': {
      label: 'Dias c/ testagem p/ pessoas c/ sintomas',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'dias',
      description: 'Políticas sobre testes para infecção (testes de PCR - Polymerase Chain Reaction) - não políticas sobre testes de imunidade (testes de anticorpos)',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Testing policy - days with no testing policy': {
      label: 'Dias s/ política de testagem',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'dias',
      description: 'Políticas sobre testes para infecção (testes de PCR - Polymerase Chain Reaction) - não políticas sobre testes de imunidade (testes de anticorpos)',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Testing policy - only symptoms/specific criteria': {
      label: 'Dias c/ testagem - c/ sintomas ou critério específico',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'dias',
      description: 'Políticas sobre testes para infecção (testes de PCR - Polymerase Chain Reaction) - não políticas sobre testes de imunidade (testes de anticorpos)',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Testing policy - open public testing': {
      label: 'Dias c/ testagem aberta p/ a população',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'dias',
      description: 'Políticas sobre testes para infecção (testes de PCR - Polymerase Chain Reaction) - não políticas sobre testes de imunidade (testes de anticorpos)',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Transit stations percent change from baseline': {
      label: 'Mobilidade: estações de transporte',
      category: 'Mobilidade',
      source: 'Google',
      unit: '% de variação sobre a referência',
      description: 'Tendências de mobilidade de lugares como terminais de transporte público, tipo estações de metrô, ônibus e trem. Estes conjuntos de dados mostram como as visitas e o tempo de permanência em locais diferentes mudam em comparação com um valor base. Utilizamos um deslocamento de três semanas para melhor correlação com o número de mortes, levando em consideração uma aproximação dos períodos de incubação e tratamento.',
      url: 'https://www.google.com/covid19/mobility/',
    },
    'Urban areas over 1M inhabitants': {
      label: 'Núm. de áreas urbanas - mais de 1 milhão de habit.',
      category: 'Demografia',
      source: 'Demographia',
      unit: 'Número de áreas urbanas por país',
      description: 'Uma área urbana ("área urbana construída, área urbanizada ou aglomeração urbana) é uma massa terrestre de desenvolvimento urbano continuamente construída dentro de um mercado de trabalho (área metropolitana ou região metropolitana). Uma área urbana não contém terras rurais (todas As terras do mundo são urbanas ou rurais.) Em algumas nações, o termo "área urbana" é usado, mas não indica uma área urbana construída.',
      url: 'http://www.demographia.com/db-worldua.pdf',
    },
    'Urban areas over 2M inhabitants': {
      label: 'Núm. de áreas urbanas - mais de 2 milhões de habit.',
      category: 'Demografia',
      source: 'Demographia',
      unit: 'Número de áreas urbanas por país',
      description: 'Uma área urbana ("área urbana construída, área urbanizada ou aglomeração urbana) é uma massa terrestre de desenvolvimento urbano continuamente construída dentro de um mercado de trabalho (área metropolitana ou região metropolitana). Uma área urbana não contém terras rurais (todas As terras do mundo são urbanas ou rurais.) Em algumas nações, o termo "área urbana" é usado, mas não indica uma área urbana construída.',
      url: 'http://www.demographia.com/db-worldua.pdf',
    },
    'Urban areas over 500k inhabitants': {
      label: 'Núm. de áreas urbanas c/ mais de 500 mil habit.',
      category: 'Demografia',
      source: 'Demographia',
      unit: 'Número de áreas urbanas por país',
      description: 'Uma área urbana ("área urbana construída, área urbanizada ou aglomeração urbana) é uma massa terrestre de desenvolvimento urbano continuamente construída dentro de um mercado de trabalho (área metropolitana ou região metropolitana). Uma área urbana não contém terras rurais (todas As terras do mundo são urbanas ou rurais.) Em algumas nações, o termo "área urbana" é usado, mas não indica uma área urbana construída.',
      url: 'http://www.demographia.com/db-worldua.pdf\nhttps://en.wikipedia.org/wiki/Number_of_urban_areas_by_country',
    },
    'Urban areas over 5M inhabitants': {
      label: 'Núm. de áreas urbanas - mais de 5 milhões de habit.',
      category: 'Demografia',
      source: 'Demographia',
      unit: 'Número de áreas urbanas por país',
      description: 'Uma área urbana ("área urbana construída, área urbanizada ou aglomeração urbana) é uma massa terrestre de desenvolvimento urbano continuamente construída dentro de um mercado de trabalho (área metropolitana ou região metropolitana). Uma área urbana não contém terras rurais (todas As terras do mundo são urbanas ou rurais.) Em algumas nações, o termo "área urbana" é usado, mas não indica uma área urbana construída.',
      url: 'http://www.demographia.com/db-worldua.pdf',
    },
    'Urban population (% population)': {
      label: 'População urbana',
      category: 'Demografia',
      source: 'Banco Mundial',
      unit: '% da pop.',
      description: 'População urbana refere-se a pessoas que vivem em áreas urbanas, conforme definido pelos escritórios nacionais de estatística. Os dados são coletados e suavizados pela Divisão de População das Nações Unidas.',
      url: 'https://data.worldbank.org/indicator/SP.URB.TOTL.in.zs',
    },
    'Vaccination policy - available for ALL the risk groups': {
      label: 'Dias c/ vacina para todos os grupos prioritários',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'dias',
      description: 'Registro da disponibilidade de vacinas. São considerados grupos prioritários profissionais essenciais, pessoas clinicamente vulneráveis e idosos',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Vaccination policy - available for ALL the risk groups plus partial additional availability': {
      label: 'Dias c/ vacina para os grupos prioritários e outros',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'dias',
      description: 'Registro da disponibilidade de vacinas. São considerados grupos prioritários profissionais essenciais, pessoas clinicamente vulneráveis e idosos',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Vaccination policy - available for ONE of the risk groups': {
      label: 'Dias c/ vacina para UM dos grupos prioritários',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'dias',
      description: 'Registro da disponibilidade de vacinas. São considerados grupos prioritários profissionais essenciais, pessoas clinicamente vulneráveis e idosos',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Vaccination policy - available for TWO of the risk groups': {
      label: 'Dias c/ vacina para DOIS dos grupos prioritários',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'dias',
      description: 'Registro da disponibilidade de vacinas. São considerados grupos prioritários profissionais essenciais, pessoas clinicamente vulneráveis e idosos',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Vaccination policy - days with no availability': {
      label: 'Dias s/ disponibilidade de vacinas',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'dias',
      description: 'Registro da disponibilidade de vacinas',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Vaccination policy - universal availability': {
      label: 'Dias c/ disponibilidade universal de vacinas',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'dias',
      description: 'Registro da disponibilidade de vacinas',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Workplaces - days with no measures': {
      label: 'Dias s/ medidas de fechamento de empresas',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'dias',
      description: 'Registro de fechamentos de locais de trabalho, como escritórios comerciais',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Workplaces - recommend closing': {
      label: 'Dias c/ recomendação p/ fechamento de empresas',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'dias',
      description: 'Registro de fechamentos de locais de trabalho, como escritórios comerciais',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Workplaces - require closing (all-but-essential)': {
      label: 'Dias c/ fech. exigido de empresas - exceto essenciais',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'dias',
      description: 'Registro de fechamentos de locais de trabalho, como escritórios comerciais',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Workplaces - require closing (for some categories)': {
      label: 'Dias c/ exigência de fech. de empresas - alguns setores',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'dias',
      description: 'Registro de fechamentos de locais de trabalho, como escritórios comerciais',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'transmission rate (R)': {
      label: 'Taxa de transmissão (R)',
      category: 'Saúde',
      source: 'Our World in Data',
      unit: 'pontos',
      description: 'Real-time estimate of the effective reproduction rate (R) of COVID-19. More information at \nhttps://github.com/crondonm/TrackingR/tree/main/Estimates-Database',
      url: 'http://covid.ourworldindata.org/',
    },
    'cumulative number of tests performed (per 100k)': {
      label: 'Número acumulado de testes executados',
      category: 'Saúde',
      source: 'Our World in Data',
      unit: '/100k',
      description: 'Cumulative number of tests performed',
      url: 'http://covid.ourworldindata.org/',
    },
    'Vaccinated people (% of population)': {
      label: 'Pessoas vacinadas',
      category: 'Saúde',
      source: 'Our World in Data',
      unit: '% da população',
      description: 'Share of the population that received at least one covid-19 vaccine dose',
      url: 'http://covid.ourworldindata.org/',
    },
    'Fully vaccinated people (% of population)': {
      label: 'Pessoas imunizadas',
      category: 'Saúde',
      source: 'Our World in Data',
      unit: '% da população',
      description: 'Share of the population that received all required doses of any covid-19 vaccine',
      url: 'http://covid.ourworldindata.org/',
    },
    'stringency index': {
      label: 'Indíce de restrição',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'pontos',
      description: 'Stringency index (abrange todos os indicadores do tipo C, além do H1 que registra campanhas de informação pública). Mais informações em https://github.com/OxCGRT/covid-policy-tracker/blob/master/documentation/index_methodology.md',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'government response index': {
      label: 'Indíce de resposta governamental',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'pontos',
      description: 'Indíce geral da resposta governamental à pandemia (abrange todos os indicadores). Mais informações em https://github.com/OxCGRT/covid-policy-tracker/blob/master/documentation/index_methodology.md',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'containment and health index': {
      label: 'Indíce de contenção e saúde',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'pontos',
      description: 'Indíce de contenção e saúde (abrange todos os indicadores dos tipos C e H). Mais informações em https://github.com/OxCGRT/covid-policy-tracker/blob/master/documentation/index_methodology.md',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'economic support index': {
      label: 'Índice de suporte econômico',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'pontos',
      description: 'Indíce de suporte econômico (abrange todos os indicadores do tipo E). Mais informações em https://github.com/OxCGRT/covid-policy-tracker/blob/master/documentation/index_methodology.md',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Protection of elderly people - days with no measures': {
      label: 'Proteção de idosos (s/ medidas)',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'dias',
      description: 'Registro da proteção das pessoas idosas (principalmente as que vivem em casas de repouso)',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Protection of elderly people - recommend isolation': {
      label: 'Proteção de idosos (recomendação)',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'dias',
      description: 'Registro da proteção das pessoas idosas (principalmente as que vivem em casas de repouso)',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Protection of elderly people - narrow restriction for isolation': {
      label: 'Proteção de idosos (exigência de algum isolamento)',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'dias',
      description: 'Registro da proteção das pessoas idosas (principalmente as que vivem em casas de repouso)',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Protection of elderly people - extensive restriction for isolation': {
      label: 'Proteção de idosos (exigência de total isolamento)',
      category: 'Políticas',
      source: 'Universidade de Oxford',
      unit: 'dias',
      description: 'Registro da proteção das pessoas idosas (principalmente as que vivem em casas de repouso)',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    p1_present: {
      label: 'Variante P.1 (brasileira) presente',
      category: 'Saúde',
      source: 'Pangolin - Pangolin - Atribuição Filogenética de Linhagens Nomeadas de Surto Global',
      unit: 'Binário (sim ou não)',
      description: 'Registro da presença da variante do coronavírus P.1 (originada no Brasil) no país',
      url: 'https://github.com/cov-lineages/grinch/tree/main/grinch/data',
    },
    p1_days: {
      label: 'Dias com a variante P.1 (brasileira) presente',
      category: 'Saúde',
      source: 'Pangolin - Pangolin - Atribuição Filogenética de Linhagens Nomeadas de Surto Global',
      unit: 'dias',
      description: 'Registro do número de dias que a variante do coronavírus P.1 (originada no Brasil) está presente no país',
      url: 'https://github.com/cov-lineages/grinch/tree/main/grinch/data',
    },
    b117_present: {
      label: 'Variante B.1.1.7 (Reino Unido) presente',
      category: 'Saúde',
      source: 'Pangolin - Pangolin - Atribuição Filogenética de Linhagens Nomeadas de Surto Global',
      unit: 'Binário (sim ou não)',
      description: 'Registro da presença da variante do coronavírus B.1.1.7 (originada no Reino Unido) no país',
      url: 'https://github.com/cov-lineages/grinch/tree/main/grinch/data',
    },
    b117_days: {
      label: 'Dias com a variante B.1.1.7 (Reino Unido) presente',
      category: 'Saúde',
      source: 'Pangolin - Pangolin - Atribuição Filogenética de Linhagens Nomeadas de Surto Global',
      unit: 'dias',
      description: 'Registro do número de dias que a do coronavírus variante B.1.1.7 (originada no Reino Unido) está presente no país',
      url: 'https://github.com/cov-lineages/grinch/tree/main/grinch/data',
    },
    b1351_present: {
      label: 'Variante B.1.351 (África do Sul) presente',
      category: 'Saúde',
      source: 'Pangolin - Pangolin - Atribuição Filogenética de Linhagens Nomeadas de Surto Global',
      unit: 'Binário (sim ou não)',
      description: 'Registro da presença da variante do coronavírus B.1.351 (originada na África do Sul) no país',
      url: 'https://github.com/cov-lineages/grinch/tree/main/grinch/data',
    },
    b1351_days: {
      label: 'Dias com a variante B.1.351 (África do Sul) presente',
      category: 'Saúde',
      source: 'Pangolin - Pangolin - Atribuição Filogenética de Linhagens Nomeadas de Surto Global',
      unit: 'dias',
      description: 'Registro do número de dias que a variante do coronavírus B.1.351 (originada na África do Sul) está presente no país',
      url: 'https://github.com/cov-lineages/grinch/tree/main/grinch/data',
    },
  },
};
