import { ROOT } from './config';
import detectPeaks from '../math/detectPeaks';

const mapFeatureImportance = ([key, {
  shap,
  val,
  avg,
  med,
}]) => [key, {
  importance: shap,
  global_median: med,
  global_mean: avg,
  value: val,
}];

const sortFeatureImportances = (a, b) => b.importance - a.importance;

const mapFeatureImportances = (importances) => Object.fromEntries(
  importances.map(mapFeatureImportance)
    .sort(sortFeatureImportances),
);

const mapAcceleration = ({ historic, acceleration }) => {
  const data = historic.map((d, i) => ({
    date: Date.parse(d.date),
    actual: acceleration.real[i],
    predicted: acceleration.pred[i],
    expected: acceleration.expected[i],
    features: mapFeatureImportances(Object.entries(acceleration.features[i])),
  }));

  const peaks = detectPeaks(data.map((d) => d.predicted), { coalesce: 14, lookaround: 28 });

  return {
    data,
    peaks,
  };
};

const mapVelocity = ({ historic, velocity }) => {
  const data = historic.map((d, i) => ({
    date: Date.parse(d.date),
    actual: velocity.real[i],
    predicted: velocity.pred[i],
    expected: velocity.expected[i],
    features: mapFeatureImportances(Object.entries(velocity.features[i])),
  }));

  const peaks = detectPeaks(data.map((d) => d.predicted), { coalesce: 14, lookaround: 56 });

  return {
    data,
    peaks,
  };
};

async function loadLocation(path, locationCode, root = ROOT) {
  const response = await fetch(`${root}/${path}/${locationCode}.json`);
  const data = await response.json();

  return {
    acceleration: mapAcceleration(data),
    velocity: mapVelocity(data),
  };
}

export default loadLocation;
