import ShapChart from './ShapChart';

export default class ShapForceChart extends ShapChart {
  constructor(...args) {
    super(...args);

    this.leadsToLabel = 'Leads to';
    this.moreDeathsLabel = 'MORE DEATHS';
    this.lessDeathsLabel = 'LESS DEATHS';

    this.yMiddle = this.margin.top + (this.height - this.margin.top - this.margin.bottom) / 2;

    this.zero = this.chart.append('line')
      .classed('chart-zero-line', true)
      .attr('opacity', 0.75)
      .attr('stroke', '#000000')
      .attr('stroke-dasharray', 2)
      .attr('x1', 20)
      .attr('x2', this.width - this.margin.right)
      .attr('y1', this.yMiddle)
      .attr('y2', this.yMiddle);

    this.yAxisTitle = this.chart.append('text')
      .classed('chart-axis-title', true)
      .attr('fill', 'currentColor')
      .attr('font-size', 10)
      .attr('transform', `translate(0, ${this.yMiddle}) rotate(270)`);

    this.negativeLeadsTo = this.yAxisTitle.append('tspan')
      .attr('x', -18)
      .attr('y', 24)
      .attr('dominant-baseline', 'central')
      .attr('text-anchor', 'end')
      .style('white-space', 'pre');

    this.lessDeaths = this.yAxisTitle.append('tspan')
      .attr('x', -18)
      .attr('y', 36)
      .attr('dominant-baseline', 'central')
      .attr('font-weight', 'bold')
      .attr('text-anchor', 'end')
      .style('white-space', 'pre');

    this.positiveLeadsTo = this.yAxisTitle.append('tspan')
      .attr('x', 18)
      .attr('y', 24)
      .attr('dominant-baseline', 'central')
      .attr('text-anchor', 'start')
      .style('white-space', 'pre');

    this.moreDeaths = this.yAxisTitle.append('tspan')
      .attr('x', 18)
      .attr('y', 36)
      .attr('dominant-baseline', 'central')
      .attr('font-weight', 'bold')
      .attr('text-anchor', 'start')
      .style('white-space', 'pre');
  }

  updateDomainY(domain, scale) {
    const domainMax = Math.max(...domain.map(Math.abs));
    const domainBalanced = [-domainMax, +domainMax];

    super.updateDomainY(domainBalanced, scale);
  }

  render() {
    this.negativeLeadsTo.text(this.leadsToLabel);
    this.positiveLeadsTo.text(this.leadsToLabel);

    this.lessDeaths.text(this.lessDeathsLabel);
    this.moreDeaths.text(this.moreDeathsLabel);
  }
}
