import * as d3 from 'd3';
import collapseOther from '../math/collapseOther';
import ShapForceChart from './ShapForceChart';
import WithMarkers from './WithMarkers';

class ShapForceAreaChart extends ShapForceChart {
  constructor(...args) {
    super(...args);

    this.chart.classed('chart-diverging-stacked-area', true);
  }

  static buildStack(data, labels) {
    const negative = d3.stack()
      .offset(d3.stackOffsetDiverging)
      .keys(labels)
      .value((d, key) => {
        const entry = d.features[key];
        return entry ? Math.min(entry.importance, -0.000001) : -0.000001;
      })(data);

    const positive = d3.stack()
      .offset(d3.stackOffsetDiverging)
      .keys(labels)
      .value((d, key) => {
        const entry = d.features[key];
        return entry ? Math.max(entry.importance, +0.000001) : +0.000001;
      })(data);

    const grouped = {};

    labels.forEach((key) => { grouped[key] = { key }; });
    positive.forEach((d) => { grouped[d.key].positive = d; });
    negative.forEach((d) => { grouped[d.key].negative = d; });

    return Object.values(grouped);
  }

  render(data, meta = {}, sortIndex = 0) {
    super.render();

    const topLabels = Object.keys(data[sortIndex].features).slice(0, 10);
    const topValues = collapseOther(data, topLabels);

    const labels = Object.keys(topValues[sortIndex].features);
    const values = this.constructor.buildStack(topValues, labels);

    const xDomain = new Set(data.map((d) => d.date));
    const yDomain = [
      d3.min(values, (d) => d3.min(d.negative, (p) => p[0])),
      d3.max(values, (d) => d3.max(d.positive, (p) => p[1])),
    ];

    this.updateDomainX(xDomain);
    this.updateDomainY(yDomain);

    const colors = d3.scaleOrdinal()
      .domain(labels)
      .range([
        '#66cc99', '#336666', '#ff9999', '#993333', '#66ccff',
        '#0099cc', '#6666ff', '#333399', '#ffcc33', '#996633',
        '#cccccc',
      ]);

    const shape = d3.area()
      .curve(d3.curveBasis)
      .x((d) => this.xScale(d.data.date))
      .y0((d) => this.yScale(d[0]))
      .y1((d) => this.yScale(d[1]));

    const groups = this.shapes.selectAll('.chart-series')
      .data(values);

    groups.exit().remove();

    const newGroups = groups.enter().append('g')
      .classed('chart-series', true);

    newGroups.append('path')
      .classed('chart-series-area', true)
      .classed('chart-series-area-positive', true)
      .attr('fill', 'currentColor')
      .attr('stroke', 'none');

    newGroups.append('path')
      .classed('chart-series-area', true)
      .classed('chart-series-area-negative', true)
      .attr('fill', 'currentColor');

    const allGroups = newGroups.merge(groups);

    allGroups.attr('color', (d) => colors(d.key));
    allGroups.select('.chart-series-area-positive')
      .attr('d', (d) => shape(d.positive));
    allGroups.select('.chart-series-area-negative')
      .attr('d', (d) => shape(d.negative));

    this.updateTooltips(topValues, meta);

    allGroups.on('mouseleave.tooltip', this.hideTooltip.bind(this));
    allGroups.on('mousemove.tooltip', this.showTooltip.bind(this));
  }
}

export default WithMarkers(ShapForceAreaChart);
