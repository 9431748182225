import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import enUS from './locales/en-US';
import ptBR from './locales/pt-BR';

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    load: 'currentOnly',
    supportedLngs: [
      'en-US',
      'pt-BR',
    ],
    fallbackLng: {
      default: ['en-US'],
    },
    resources: {
      'en-US': { translation: enUS },
      'pt-BR': { translation: ptBR },
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
