export default {
  title: 'What Affects COVID-19 Death Rates',

  format: {
    decimal: '.',
    thousands: ',',
    grouping: [3],
    currency: ['$', ''],
  },

  timeFormat: {
    dateTime: '%x, %X',
    date: '%-m/%-d/%Y',
    time: '%-I:%M:%S %p',
    periods: ['AM', 'PM'],
    days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    months: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    shortMonths: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
  },

  nav: {
    world: 'World',
    brazil: 'Brazil',
    home: 'Home',
    methodology: 'Methodology',
    faq: 'FAQ',
  },

  charts: {
    relativeDate: 'days since 1st death',
    predictions: {
      real: 'Actual   ',
      pred: 'Predicted',
    },
    shapForce: {
      leadsTo: 'Leads to',
      moreDeaths: 'More Deaths',
      lessDeaths: 'Less Deaths',
      location: 'Selected Country',
      average: 'Dataset Average ',
    },
    shapWaterfall: {
      prediction: 'Prediction',
      otherFeatures: 'other variables',
    },
  },

  home: {
    selectLocation: 'Select the Country',
    sidebar: {
      heading:
        'This tool <strong>displays two metrics to evaluate the variation in death rates due to COVID-19 and identifies the variables that most influence these numbers</strong> at the peak of the local epidemic.',
      body:
        'Our machine learning models take into account data from dozens of sources including COVID-19 death counts, travel data, health infrastructure indicators, and general demographic data (such as GINI, GDP, age distribution, etc). <0>Full methodology, paper and data sources can be found here</0>.',
      updatedAt: 'Updated at:',
    },
    velocity: {
      histogram: {
        title: 'Daily death rate',
        description:
          'This chart shows the daily number of deaths per 100 thousand people (a metric expressed in our paper as "velocity") — smoothed by a 7-day moving average. The vertical scale may vary between selections.',
      },
      peak: {
        title: 'Select a peak',
        description:
          'Choose a date to see the influence of variables on that day in the next charts.',
      },
      waterfall: {
        title: 'Most impactful variables at the selected peak',
        description:
          "Selection of the 10 most influential variables on the model's prediction, descendingly sorted from top to bottom. Variables in red lead to a more pessimistic prediction, while those in blue lead to a more optimistic prediction — the extent of the impact in either direction is shown by the length of the bar.",
      },
      featuresArea: {
        title: 'Impact of variables over time',
        description:
          'This chart shows the impact the variables on the selected peak have over the daily death rate since the first local death. The area of each variable on the chart reveals its impact on the prediction. Values located above the horizontal baseline indicate the variable leads to a more pessimistic prediction, while those below lead to a more optimistic prediction.',
      },
      featuresLine: {
        title: 'Importance of variables over time',
        description:
          'This chart shows the same data as above, displayed as individual lines. Their position from the baseline indicates the impact on the outcome: variables above the horizontal baseline lead to a more pessimistic prediction, while those below lead to a more optimistic prediction.',
      },
    },
    acceleration: {
      histogram: {
        title: '14-day death rate variation',
        description:
          'This chart shows the number of deaths per 100 thousand people in a specific day, subtracted the same data 14 days prior (a metric expressed in our paper as "acceleration"). Line smoothed by a 7-day moving average.',
      },
      peak: {
        title: 'Select a peak',
        description:
          'Choose a date to see the influence of variables on that day in the next charts.',
      },
      waterfall: {
        title: 'Most impactful variables at the selected peak',
        description:
          "Selection of the 10 most influential variables on the model's prediction, descendingly sorted from top to bottom. Variables in red lead to a more pessimistic prediction, while those in blue lead to a more optimistic prediction. The extent of the impact in either direction is shown by the length of the bar. ",
      },
      featuresArea: {
        title: 'Impact of variables over time',
        description:
          'This chart shows the impact the variables on the selected peak have over the death rate variation since the first local death. The area of each variable on the chart reveals its impact on the prediction. Values located above the horizontal baseline indicate the variable leads to a more pessimistic prediction, while those below lead to a more optimistic prediction.',
      },
      featuresLine: {
        title: 'Impact of variables over time',
        description:
          'This chart shows the same data as above: the impact of each variable on the death rate variation for each day since the first local death. Here, variables are displayed as individual lines for each variable. Their position from the baseline indicates the impact on the outcome: variables above the horizontal baseline lead to a more pessimistic prediction, while those below lead to a more optimistic prediction.',
      },
    },
  },

  methodology: {
    title: 'Methodology',
    sidebar: {
      title: 'About',
      body: [
        '<strong>The research behind this work is available as a <a href="https://www.medrxiv.org/content/10.1101/2020.07.04.20146423v1.full.pdf">preprint</a> in medRxiv.</strong>',
        'This tool was developed by <a href="https://kunumi.com">Kunumi</a>, an organization committed to the development of science and technology, along with LIA-UFMG (Artificial Intelligence Laboratory from Minas Gerais Federal University).',
        'It is part of our efforts to better understand and combat the pandemic, by allowing decision makers, scientists, medical staff and the general public to understand which factors are more decisive in determining the spread and lethality of the virus. You can find more of our findings and initiatives related to the pandemic in <a href="https://www.linkedin.com/company/kunumi">our social media channels</a>.',
        'This model is an ongoing project and will be constantly updated as new and more reliable data becomes available. As such, the findings portrayed here do not represent definitive conclusions, but are the start of a conversation about the current moment we are living in the course of the pandemic. We invite the scientific community to participate: do not hesitate to <a href="mailto: covid-19@kunumi.com">contact us</a> with your considerations about this tool and we will find ways to collaborate.',
      ],
    },
    intro: [
      'The data shown is the result of predictions of past dates made by an interpretable tree-based machine learning model — a subgroup in artificial intelligence — which is fed a number of data points and attempts to predict two variables:',
      '1. <strong>Velocity</strong>, as expressed by the slope of the death toll as a function of time (deaths/day). Referred in this tool as daily death rate.',
      '2. <strong>Acceleration</strong>, as expressed by the slope of the velocity as a function of time (deaths/day²). Referred in this tool as 14-day death rate variation.',
      "At the peak of the epidemic in any selected country, the tool also displays the importance of each variable for the model's predictions on that date — calculated through the Shapley Additive exPlanation SHAP (Lundberg e Lee, 2007) values, a method based on the Shapley Values game theory technique, used to determine how much different players contribute to the success of a collaborative game.",
      'The SHAP values measure how much each variable in a machine learning model individually contributes — positively or negatively — to the final outcome, allowing for fine explanation of the overall prediction.',
    ],
    sources: {
      title: 'Data Sources',
      body: [
        'This model utilizes data from the following sources: World Bank, World Life Expectancy, University of Oxford, Our World In Data and John Hopkins University. Below, you will find the source and description of each variable considered by the model.',
      ],
    },
    locations: {
      title: 'Countries Studied',
      body: [
        'The countries analyzed in the present version of this tool were selected in an attempt to form a representative sample in terms of continents, pandemic phases and socioeconomic conditions — given that high quality data and reliable data was available. More countries are expected to be added in the following weeks, and suggestions are appreciated.',
        'As of today, the list includes: Australia, Belgium, Brazil, Canada, Chile, China, Denmark, Egypt, France, Germany, India, Iran, Israel, Italy, Japan, Mexico, Netherlands, New Zealand, Nigeria, South Africa, Spain, Sweden, United Kingdom, United States.',
      ],
    },
    training: {
      title: 'Training Data',
      body: [
        'The present version of this model is trained with data from the following countries: Afghanistan, Algeria, Argentina, Australia, Austria, Bangladesh, Belarus, Belgium, Bolivia, Bosnia and Herzegovina, Brazil, Bulgaria, Burkina Faso, Cameroon, Canada, Chile, China, Costa Rica, Croatia, Cuba, Denmark, Dominican Republic, Egypt, Estonia, Finland, France, Germany, Ghana, Greece, Guatemala, Guinea, Guyana, Haiti, Honduras, Hungary, India, Indonesia, Iran, Ireland, Israel, Italy, Japan, Jordan, Kuwait, Latvia, Lithuania, Luxembourg, Madagascar, Malawi, Malaysia, Mexico, Moldova, Morocco, Nepal, Netherlands, New Zealand, Nicaragua, Niger, Nigeria, Norway, Oman, Pakistan, Paraguay, Philippines, Poland, Portugal, Qatar, Saudi Arabia, Senegal, Slovakia, Slovenia, South Africa, Spain, Sri Lanka, Suriname, Sweden, Switzerland, Tajikistan, Tanzania, Thailand, Togo, Trinidad and Tobago, Tunisia, UAE, UK, USA, Uzbekistan.',
      ],
    },
    glossary: {
      title: 'Variable Glossary',
      body: [
        'The whole set of distinct variables analyzed by the model and their significance are described in the following glossary.',
      ],
    },
  },

  faq: {
    title: 'Frequently Asked Questions',
    sidebar: {
      title: "Didn't find your answer?",
      body: [
        'These questions and answers were chosen based on how frequently they were asked by our researchers, co-workers and peers. We are constantly updating this document with newer questions that arise.',
        'If you have a question that was not answered, feel free to <a href="mailto: covid-19@kunumi.com">contact us</a>.',
      ],
    },
    questions: [
      {
        question: 'What about countries without clear peaks?',
        answer:
          'The tool displays variables that most influence COVID-19 rates in the local epidemic peak. In places without a clear peak, such as Brazil, it is important to take into account that the selection of variables could change while the epidemic is stabilized in a plateau.',
      },
      {
        question:
          'Why does the impact of some variables change from reducing to increasing mortality rates (and vice-versa)?',
        answer:
          'Some variables — like average temperature — change their values over time, while others can have different influences at the initial or final days of the pandemic  — such as the percentage of elderly people in the population. There are also certain variables that could take days to have an effect — like public information campaigns and restriction policies.',
      },
      {
        question: 'Why do constant variables change their importance over time?',
        answer:
          'There are two main reasons: the variable may have differing importance in different moments of the curve; or its interaction with other dynamic variables may influence its importance on each day.',
      },
      {
        question:
          'For some gender related variables, why may the male or female counterparts not appear in the graph? Or why may age related variables not contemplate all or specific age groups?',
        answer:
          'The male or female counterparts of gender variables — as well as all buckets of age variables — do exist and were included in the training data, but some of them were not amongst the most significant variables for that specific, and thus are not shown in the charts.',
      },
      {
        question:
          'Why do some variables contribute to more predicted deaths even when their values appear better than average? Or why do some variables contribute to less predicted deaths when they appear worse than average?',
        answer:
          'The averages shown in the charts are calculated from the sample of countries considered by our models. Since not all countries were included, they are not actual World averages. Moreover, variables near World averages can still have negative or positive influence on the number of deaths.',
      },
      {
        question: 'Why are there some days in the end of May with a negative death toll for Spain?',
        answer:
          'On May 25th, Spain changed the way of data collecting, by counting a death based on when it happened. As a result, its authorities decided to apply the all differences in death toll at once, instead of changing previous reports. It affected either the moving average of death toll and the prediction model.',
      },
    ],
  },

  locations: {
    AU: 'Australia',
    BE: 'Belgium',
    BR: 'Brazil',
    CA: 'Canada',
    CL: 'Chile',
    CN: 'China',
    DE: 'Germany',
    DK: 'Denmark',
    EG: 'Egypt',
    ES: 'Spain',
    FR: 'France',
    IL: 'Israel',
    IN: 'India',
    IR: 'Iran',
    IT: 'Italy',
    JP: 'Japan',
    MX: 'Mexico',
    NG: 'Nigeria',
    NL: 'Netherlands',
    NZ: 'New Zealand',
    SE: 'Sweden',
    UK: 'United Kingdom',
    US: 'United States',
    ZA: 'South Africa',
  },

  baseFeature: {
    category: 'Uncategorized',
    description: 'This variable has no description yet...',
    source: '(Under revision)',
    unit: '',
  },

  features: {
    '...rest': {
      label: '...other variables',
    },
    '100-year olds (males per 100 females)': {
      label: 'Gender ratio of 100-year old',
      category: 'Demographics',
      source: 'Our World in Data',
      unit: 'males/100 females',
      description: 'Gender ratio (or sex ratio) is the proportion of males to females in a given population, usually expressed as the number of males per 100 females',
      url: 'https://ourworldindata.org/gender-ratio',
    },
    '40-year olds (males per 100 females)': {
      label: 'Gender ratio of 40-year old',
      category: 'Demographics',
      source: 'Our World in Data',
      unit: 'males/100 females',
      description: 'Gender ratio (or sex ratio) is the proportion of males to females in a given population, usually expressed as the number of males per 100 females',
      url: 'https://ourworldindata.org/gender-ratio',
    },
    '50-year olds (males per 100 females)': {
      label: 'Gender ratio of 50-year old',
      category: 'Demographics',
      source: 'Our World in Data',
      unit: 'males/100 females',
      description: 'Gender ratio (or sex ratio) is the proportion of males to females in a given population, usually expressed as the number of males per 100 females',
      url: 'https://ourworldindata.org/gender-ratio',
    },
    '60-year olds (males per 100 females)': {
      label: 'Gender ratio of 60-year old',
      category: 'Demographics',
      source: 'Our World in Data',
      unit: 'males/100 females',
      description: 'Gender ratio (or sex ratio) is the proportion of males to females in a given population, usually expressed as the number of males per 100 females',
      url: 'https://ourworldindata.org/gender-ratio',
    },
    '70-year olds (males per 100 females)': {
      label: 'Gender ratio of 70-year old',
      category: 'Demographics',
      source: 'Our World in Data',
      unit: 'males/100 females',
      description: 'Gender ratio (or sex ratio) is the proportion of males to females in a given population, usually expressed as the number of males per 100 females',
      url: 'https://ourworldindata.org/gender-ratio',
    },
    '80-year olds (males per 100 females)': {
      label: 'Gender ratio of 80-year old',
      category: 'Demographics',
      source: 'Our World in Data',
      unit: 'males/100 females',
      description: 'Gender ratio (or sex ratio) is the proportion of males to females in a given population, usually expressed as the number of males per 100 females',
      url: 'https://ourworldindata.org/gender-ratio',
    },
    '90-year olds (males per 100 females)': {
      label: 'Gender ratio of 90-year old',
      category: 'Demographics',
      source: 'Our World in Data',
      unit: 'males/100 females',
      description: 'Gender ratio (or sex ratio) is the proportion of males to females in a given population, usually expressed as the number of males per 100 females',
      url: 'https://ourworldindata.org/gender-ratio',
    },
    'Agglomerations of +1 million (% of urban population)': {
      label: 'Population in urban agglomerations of +1 million',
      category: 'Demographics',
      source: 'World Bank',
      unit: '% of urban pop.',
      description: "Population in urban agglomerations of more than one million is the percentage of a country's population living in metropolitan areas that in 2018 had a population of more than one million people.",
      url: 'https://data.worldbank.org/indicator/en.urb.mcty.tl.zs',
    },
    'Average Temperature (C)': {
      label: 'Average temperature',
      category: 'Climate',
      source: 'National Centers for Environmental Information (NCEI)',
      unit: '(°C) Celsius',
      description: 'Average of the temperatures reported during the \n day. Missing = \n 9999.9. Data is smoothed using a 7-day moving average and an offset of three weeks is applied in order to better correlate with the number of deaths by taking into account an approximation of incubation and treatment period lengths.',
      url: 'https://www.ncei.noaa.gov/access/search/data-search/global-summary-of-the-day',
    },
    'BCG coverage (%)': {
      label: 'BCG coverage as % of total country population',
      category: 'Health',
      source: 'BCG World Atlas',
      unit: '% of total country population',
      description: 'The BCG vaccine protects against tuberculosis, which is also known as TB. TB is a serious infection that affects the lungs and sometimes other parts of the body, such as the bones, joints and kidneys. The percentage of the population that have received one dose of bacille Calmette-Guérin (BCG) vaccine.',
      url: 'http://www.bcgatlas.org/index.php',
    },
    'BCG vaccination started (years)': {
      label: 'Year that country have  began BCG vaccination',
      category: 'Health',
      source: 'BCG World Atlas',
      unit: 'Year',
      description: null,
      url: 'http://www.bcgatlas.org/index.php',
    },
    'BCG vaccination stopped (years)': {
      label: 'Year that country have stopped BCG vaccination',
      category: 'Health',
      source: 'BCG World Atlas',
      unit: 'Year',
      description: null,
      url: 'http://www.bcgatlas.org/index.php',
    },
    'Contact tracing - days with no contact tracing': {
      label: 'Days w/ government not doing any contact tracing',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'days',
      description: 'Governments that are doing contact tracing',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Critical care beds needed/available': {
      label: 'Critical care beds needed per critical beds available',
      category: 'Health',
      source: 'Institute for Health Metrics and Evaluation (IHME)',
      unit: 'ratio',
      description: 'Ratio calculated of critical care beds needed and critical care beds available',
      url: 'http://www.healthdata.org/covid',
    },
    'Current BCG vaccination': {
      label: 'Current BCG vaccination program in place',
      category: 'Health',
      source: 'BCG World Atlas',
      unit: 'Binary (Yes/No)',
      description: null,
      url: 'http://www.bcgatlas.org/index.php',
    },
    'Current health expenditure (% of GDP)': {
      label: 'Current health expenditure',
      category: 'Health',
      source: 'World Bank',
      unit: '% of GDP',
      description: 'Level of current health expenditure expressed as a percentage of GDP. Estimates of current health expenditures include healthcare goods and services consumed during each year. This indicator does not include capital health expenditures such as buildings, machinery, IT and stocks of vaccines for emergency or outbreaks.',
      url: 'https://data.worldbank.org/indicator/SH.XPD.CHEX.GD.ZS',
    },
    'Days of outbreak': {
      label: 'No. of days after 1st case',
      category: 'Temporal',
      source: 'Internal',
      unit: 'days',
      description: 'Number of days passed after first confirmed case',
      url: null,
    },
    'Death rate from CVDs (per 100k)': {
      label: 'Mortality due to Cardiovascular diseases',
      category: 'Health',
      source: 'Our World in Data',
      unit: '/100k',
      description: 'Cardiovascular disease (CVD) is a term used to refer to the range of diseases which affect the heart and blood vessels. These include hypertension (high blood pressure); coronary heart disease (heart attack); cerebrovascular disease (stroke); heart failure; and other heart diseases.',
      url: 'https://ourworldindata.org/causes-of-death',
    },
    'Deaths from AIDS female (world avg)': {
      label: 'Mortality due to AIDS for female',
      category: 'Health',
      source: 'World life expectancy',
      unit: 'world avg ratio',
      description: 'ICD-10 CODES: B20-B24\nData rounded. Decimals affect ranking.\nData Source: WORLD HEALTH ORGANIZATION\nAge Adjusted Death Rate Estimates: 2017\nCalculations Provided by World Life Expectancy\nData Accuracy Not Guaranteed\nWHO Excludes Cause of Death Data For the following countries: Andorra, Cook Islands, Dominica,\nMarshall Islands, Monaco, Nauru, Niue, Palau, Saint Kitts, San Marino, Tuvalu.',
      url: 'https://www.worldlifeexpectancy.com/cause-of-death/hiv-aids/by-country/',
    },
    'Deaths from AIDS male (world avg)': {
      label: 'Mortality due to AIDS for male',
      category: 'Health',
      source: 'World life expectancy',
      unit: 'world avg ratio',
      description: 'ICD-10 CODES: B20-B24\nData rounded. Decimals affect ranking.\nData Source: WORLD HEALTH ORGANIZATION\nAge Adjusted Death Rate Estimates: 2017\nCalculations Provided by World Life Expectancy\nData Accuracy Not Guaranteed\nWHO Excludes Cause of Death Data For the following countries: Andorra, Cook Islands, Dominica, Marshall Islands, Monaco, Nauru, Niue, Palau, Saint Kitts, San Marino, Tuvalu.',
      url: 'https://www.worldlifeexpectancy.com/cause-of-death/hiv-aids/by-country/',
    },
    'Deaths from Cancer 15-49 years (world avg)': {
      label: 'Mortality due to Cancer - 15-49 years',
      category: 'Health',
      source: 'Our World in Data',
      unit: 'world avg ratio',
      description: 'Cancer is a term for diseases in which abnormal cells divide without control and can invade nearby tissues. Cancer cells can also spread to other parts of the body through the blood and lymph systems. There are several main types of cancer.',
      url: 'https://ourworldindata.org/grapher/cancer-deaths-by-age?stackMode=relative',
    },
    'Deaths from Cancer 50-69 years (world avg)': {
      label: 'Mortality due to Cancer - 50-69 years',
      category: 'Health',
      source: 'Our World in Data',
      unit: 'world avg ratio',
      description: 'Cancer is a term for diseases in which abnormal cells divide without control and can invade nearby tissues. Cancer cells can also spread to other parts of the body through the blood and lymph systems. There are several main types of cancer.',
      url: 'https://ourworldindata.org/grapher/cancer-deaths-by-age?stackMode=relative',
    },
    'Deaths from Cancer 70+ years (world avg)': {
      label: 'Mortality due to Cancer - 70+ years',
      category: 'Health',
      source: 'Our World in Data',
      unit: 'world avg ratio',
      description: 'Cancer is a term for diseases in which abnormal cells divide without control and can invade nearby tissues. Cancer cells can also spread to other parts of the body through the blood and lymph systems. There are several main types of cancer.',
      url: 'https://ourworldindata.org/grapher/cancer-deaths-by-age?stackMode=relative',
    },
    'Deaths from Cancer female (world avg)': {
      label: 'Mortality due to Cancer for female',
      category: 'Health',
      source: 'World life expectancy',
      unit: 'world avg ratio',
      description: 'Cancer is a term for diseases in which abnormal cells divide without control and can invade nearby tissues. Cancer cells can also spread to other parts of the body through the blood and lymph systems. There are several main types of cancer.',
      url: 'https://www.worldlifeexpectancy.com/cause-of-death/asthma/by-country/',
    },
    'Deaths from Cancer male (world avg)': {
      label: 'Mortality due to cancer for male',
      category: 'Health',
      source: 'World life expectancy',
      unit: 'world avg ratio',
      description: 'Cancer is a term for diseases in which abnormal cells divide without control and can invade nearby tissues. Cancer cells can also spread to other parts of the body through the blood and lymph systems. There are several main types of cancer.',
      url: 'https://www.worldlifeexpectancy.com/cause-of-death/asthma/by-country/',
    },
    'Deaths from CVDs 15-49 years (world avg)': {
      label: 'Mortality due to Cardiovascular Disease aged 15-49',
      category: 'Health',
      source: 'Our World in Data',
      unit: 'world avg ratio',
      description: 'Cardiovascular disease (CVD) is a term used to refer to the range of diseases which affect the heart and blood vessels. These include hypertension (high blood pressure); coronary heart disease (heart attack); cerebrovascular disease (stroke); heart failure; and other heart diseases.',
      url: 'https://ourworldindata.org/grapher/cardiovascular-disease-death-rates-by-age',
    },
    'Deaths from CVDs 50-69 years (world avg)': {
      label: 'Mortality due to Cardiovascular Disease aged 50-69',
      category: 'Health',
      source: 'Our World in Data',
      unit: 'world avg ratio',
      description: 'Cardiovascular disease (CVD) is a term used to refer to the range of diseases which affect the heart and blood vessels. These include hypertension (high blood pressure); coronary heart disease (heart attack); cerebrovascular disease (stroke); heart failure; and other heart diseases.',
      url: 'https://ourworldindata.org/grapher/cardiovascular-disease-death-rates-by-age',
    },
    'Deaths from CVDs 70+ years (world avg)': {
      label: 'Mortality due to Cardiovascular Disease aged 70+',
      category: 'Health',
      source: 'Our World in Data',
      unit: 'world avg ratio',
      description: 'Cardiovascular disease (CVD) is a term used to refer to the range of diseases which affect the heart and blood vessels. These include hypertension (high blood pressure); coronary heart disease (heart attack); cerebrovascular disease (stroke); heart failure; and other heart diseases.',
      url: 'https://ourworldindata.org/grapher/cardiovascular-disease-death-rates-by-age',
    },
    'Deaths from Diabetes 15-49 years (world avg)': {
      label: 'Mortality due to Diabetes aged 15-49',
      category: 'Health',
      source: 'Our World in Data',
      unit: 'world avg ratio',
      description: 'Number of deaths due to diabetes for people aged 15 to 49 years old',
      url: 'https://ourworldindata.org/grapher/causes-of-death-in-15-49-year-olds',
    },
    'Deaths from Diabetes 70+ years (world avg)': {
      label: 'Mortality due to Diabetes aged 70+',
      category: 'Health',
      source: 'Our World in Data',
      unit: 'world avg ratio',
      description: 'Number of deaths due to diabetes for people aged 70+',
      url: 'https://ourworldindata.org/grapher/causes-of-death-in-70-year-olds',
    },
    'Deaths from Diabetes female (world avg)': {
      label: 'Mortality due to Diabetes Mellitus for female',
      category: 'Health',
      source: 'World life expectancy',
      unit: 'world avg ratio',
      description: 'ICD-10 CODES: E10-E14 (minus E10.2-E10.29, E11.2-E11.29, E12.2, E13.2-E13.29, E14.2)\nData rounded. Decimals affect ranking.\nData Source: WORLD HEALTH ORGANIZATION\nAge Adjusted Death Rate Estimates: 2017\nCalculations Provided by World Life Expectancy\nData Accuracy Not Guaranteed\nWHO Excludes Cause of Death Data For the following countries: Andorra, Cook Islands, Dominica, Marshall Islands, Monaco, Nauru, Niue, Palau, Saint Kitts, San Marino, Tuvalu.',
      url: 'https://www.worldlifeexpectancy.com/cause-of-death/diabetes-mellitus/by-country/',
    },
    'Deaths from Diabetes male (world avg)': {
      label: 'Mortality due to Diabetes Mellitus for male',
      category: 'Health',
      source: 'World life expectancy',
      unit: 'world avg ratio',
      description: 'ICD-10 CODES: E10-E14 (minus E10.2-E10.29, E11.2-E11.29, E12.2, E13.2-E13.29, E14.2)\nData rounded. Decimals affect ranking.\nData Source: WORLD HEALTH ORGANIZATION\nAge Adjusted Death Rate Estimates: 2017\nCalculations Provided by World Life Expectancy\nData Accuracy Not Guaranteed\nWHO Excludes Cause of Death Data For the following countries: Andorra, Cook Islands, Dominica, Marshall Islands, Monaco, Nauru, Niue, Palau, Saint Kitts, San Marino, Tuvalu.',
      url: 'https://www.worldlifeexpectancy.com/cause-of-death/diabetes-mellitus/by-country/',
    },
    'Deaths from Stroke female (world avg)': {
      label: 'Mortality due to Stroke for female',
      category: 'Health',
      source: 'World life expectancy',
      unit: 'world avg ratio',
      description: 'ICD-10 CODES: I60-I69\nData rounded. Decimals affect ranking.\nData Source: WORLD HEALTH ORGANIZATION\nAge Adjusted Death Rate Estimates: 2017\nCalculations Provided by World Life Expectancy\nData Accuracy Not Guaranteed\nWHO Excludes Cause of Death Data For the following countries: Andorra, Cook Islands, Dominica,\nMarshall Islands, Monaco, Nauru, Niue, Palau, Saint Kitts, San Marino, Tuvalu.',
      url: 'https://www.worldlifeexpectancy.com/cause-of-death/stroke/by-country/',
    },
    'Deaths from Stroke male (world avg)': {
      label: 'Mortality due to Stroke for male',
      category: 'Health',
      source: 'World life expectancy',
      unit: 'world avg ratio',
      description: 'ICD-10 CODES: I60-I69\nData rounded. Decimals affect ranking.\nData Source: WORLD HEALTH ORGANIZATION\nAge Adjusted Death Rate Estimates: 2017\nCalculations Provided by World Life Expectancy\nData Accuracy Not Guaranteed\nWHO Excludes Cause of Death Data For the following countries: Andorra, Cook Islands, Dominica, Marshall Islands, Monaco, Nauru, Niue, Palau, Saint Kitts, San Marino, Tuvalu.',
      url: 'https://www.worldlifeexpectancy.com/cause-of-death/stroke/by-country/',
    },
    'Deaths of 15 to 49 years old (% of deaths)': {
      label: 'Deaths aged 15-49',
      category: 'Demographics',
      source: 'Our World in Data',
      unit: '% of deaths',
      description: 'Share of deaths for the age range as a percentage of the total number of deaths. Death is defined as the cessation of all vital functions of the body including the heartbeat, brain activity (including the brain stem), and breathing.',
      url: 'https://ourworldindata.org/grapher/number-of-deaths-by-age-group?stackMode=relative',
    },
    'Deaths of 50 to 69 years old (% of deaths)': {
      label: 'Deaths aged 50-69',
      category: 'Demographics',
      source: 'Our World in Data',
      unit: '% of deaths',
      description: 'Share of deaths for the age range as a percentage of the total number of deaths. Death is defined as the cessation of all vital functions of the body including the heartbeat, brain activity (including the brain stem), and breathing.',
      url: 'https://ourworldindata.org/grapher/number-of-deaths-by-age-group?stackMode=relative',
    },
    'Deaths of 70+ years old (% of deaths)': {
      label: 'Deaths aged 70+',
      category: 'Demographics',
      source: 'Our World in Data',
      unit: '% of deaths',
      description: 'Share of deaths for the age range as a percentage of the total number of deaths. Death is defined as the cessation of all vital functions of the body including the heartbeat, brain activity (including the brain stem), and breathing.',
      url: 'https://ourworldindata.org/grapher/number-of-deaths-by-age-group?stackMode=relative',
    },
    'Debt/contract relief - broad debt/contract relief': {
      label: 'Days w/ broad relief of debt obligations for households',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'days',
      description: 'Record if govt. is freezing financial obligations (e.g. stopping loan repayments, preventing services like water from stopping, or banning evictions)',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Debt/contract relief - days with no debt/contract relief': {
      label: 'Days w/o relief of financial obligations for households',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'days',
      description: 'Record if govt. is freezing financial obligations (e.g. stopping loan repayments, preventing services like water from stopping, or banning evictions)',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Debt/contract relief - narrow relief': {
      label: 'Days w/ narrow relief of debt obligations for households',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'days',
      description: 'Record if govt. is freezing financial obligations (e.g. stopping loan repayments, preventing services like water from stopping, or banning evictions)',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Dew Point (C)': {
      label: 'Dew point',
      category: 'Climate',
      source: 'National Centers for Environmental Information (NCEI)',
      unit: '(°C) Celsius',
      description: 'Mean dew point for the day. Missing = 9999.9. Data is smoothed using a 7-day moving average and an offset of three weeks is applied in order to better correlate with the number of deaths by taking into account an approximation of incubation and treatment period lengths.',
      url: 'https://www.ncei.noaa.gov/access/search/data-search/global-summary-of-the-day',
    },
    'Diabetes prevalence (% of population)': {
      label: 'Diabetes prevalence as percentage of total population',
      category: 'Health',
      source: 'World Bank',
      unit: '% of population',
      description: 'Diabetes prevalence refers to the percentage of people ages 20-79 who have type 1 or type 2 diabetes.',
      url: 'https://data.worldbank.org/indicator/SH.STA.DIAB.ZS',
    },
    'Facial coverings - days with no policy': {
      label: 'Facial coverings (no measures)',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'days',
      description: 'Record facial coverings policy',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Facial coverings - recommended': {
      label: 'Facial coverings (recommendation)',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'days',
      description: 'Record facial coverings policy',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Facial coverings - required in all shared/public places in all situations': {
      label: 'Facial coverings (required in all public places)',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'days',
      description: 'Record facial coverings policy',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Facial coverings - required in some shared/public places in some situations': {
      label: 'Facial coverings (required in some public places)',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'days',
      description: 'Record facial coverings policy',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Facial coverings - required outside home at all times': {
      label: 'Facial coverings (required outside home)',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'days',
      description: 'Record facial coverings policy',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Income - days with no income support': {
      label: 'Days w/o income support',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'Days',
      description: 'Record if the government is covering the salaries or providing direct cash payments, universal basic income, or similar, of people who lose their jobs or cannot work. (Includes payments to firms if explicitly linked to payroll/ salaries)',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Income distribution (GINI Index)': {
      label: 'Gini index',
      category: 'Economics',
      source: 'World Bank',
      unit: 'points',
      description: 'Measures the extent to which the distribution of income among individuals or households within an economy deviates from a perfectly equal distribution\n0 = perfect equality\n100 = perfect inequality',
      url: 'https://data.worldbank.org/indicator/SI.POV.GINI',
    },
    'Internal movement - days with no measures': {
      label: 'Days with no measures on internal movement restrictions',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'days',
      description: 'Record restrictions on internal movement',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Internal movement - recommend not to travel': {
      label: 'Days w/ recommended restrictions on internal movement',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'days',
      description: 'Record restrictions on internal movement',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'International travel - ban arrivals from some regions': {
      label: 'Days w/ ban of high-risk regions on international travel',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'days',
      description: 'Record restrictions on international travel',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'International travel - ban on all regions': {
      label: 'Days w/ ban on all regions on international travel',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'days',
      description: 'Record restrictions on international travel',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'International travel - days with no restrictions': {
      label: 'Days w/o measures on international travel',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'days',
      description: 'Record restrictions on international travel',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'International travel - quarantine arrivals': {
      label: "Days w/ quarantine on Int'l travel in high-risk regions",
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'days',
      description: 'Record restrictions on international travel\nOrdinal scale',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'International travel - screening arrivals': {
      label: 'Days w/ screening on international travel',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'days',
      description: 'Record restrictions on international travel',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Long-term care beds (per 100k aged 70+)': {
      label: 'Long-term care beds (/100k aged 70+)',
      category: 'Health',
      source: 'Institute for Health Metrics and Evaluation (IHME)',
      unit: '/100k - 70+ years',
      description: 'Long-term care beds refer to nursing and residential care facilities which provide accommodation and long-term care as a package. They include specially designed institutions or hospital-like settings where the predominant service component is long-term care for people with moderate to severe functional restrictions. Beds in adapted living arrangements for persons who require help while guaranteeing a high degree of autonomy and self-control are not included.',
      url: 'http://www.healthdata.org/covid',
    },
    'Maximum Temperature (C)': {
      label: 'Maximum temperature',
      category: 'Climate',
      source: 'National Centers for Environmental Information (NCEI)',
      unit: '(°C) Celsius',
      description: 'Maximum temperature reported during the \n day. Time of max \n temp report varies by country and \n region, so this will sometimes not be \n the max for the calendar day. Missing = \n 9999.9. Data is smoothed using a 7-day moving average and an offset of three weeks is applied in order to better correlate with the number of deaths by taking into account an approximation of incubation and treatment period lengths.',
      url: 'https://www.ncei.noaa.gov/access/search/data-search/global-summary-of-the-day',
    },
    'Minimum Temperature (C)': {
      label: 'Minimum temperature',
      category: 'Climate',
      source: 'National Centers for Environmental Information (NCEI)',
      unit: '(°C) Celsius',
      description: 'Minimum temperature reported during the \n day. Time of min \n temp report varies by country and \n region, so this will sometimes not be \n the min for the calendar day. Missing = \n 9999.9. Data is smoothed using a 7-day moving average and an offset of three weeks is applied in order to better correlate with the number of deaths by taking into account an approximation of incubation and treatment period lengths.',
      url: 'https://www.ncei.noaa.gov/access/search/data-search/global-summary-of-the-day',
    },
    'Population density (inhabitants per Km2)': {
      label: 'Population density',
      category: 'Demographics',
      source: 'World Bank',
      unit: 'people/km² of land area',
      description: 'For humans, population density is the number of people per unit of area, usually quoted per square kilometre or square mile, and which may include or exclude for example areas of water or glaciers.',
      url: 'https://data.worldbank.org/indicator/EN.POP.DNST',
    },
    'Population in the largest city (% urban population)': {
      label: 'Population in the largest city',
      category: 'Demographics',
      source: 'World Bank',
      unit: '% of population',
      description: "Population in largest city is the percentage of a country's urban population living in that country's largest metropolitan area.",
      url: 'https://data.worldbank.org/indicator/EN.URB.LCTY.UR.ZS',
    },
    'Population living in slums (% urban population)': {
      label: 'Population living in slums',
      category: 'Demographics',
      source: 'World Bank',
      unit: '% of urban population',
      description: 'Population living in slums is the proportion of the urban population living in slum households. A slum household is defined as a group of individuals living under the same roof lacking one or more of the following conditions: access to improved water, access to improved sanitation, sufficient living area, and durability of housing.',
      url: 'https://data.worldbank.org/indicator/en.pop.slum.ur.zs',
    },
    Precipitation: {
      label: 'Precipitation',
      category: 'Climate',
      source: 'National Centers for Environmental Information (NCEI)',
      unit: 'hundredths of inches',
      description: 'Total precipitation (rain and/or melted snow) reported during the day; will usually not end  with the midnight observation--i.e.,  may include latter part of previous day. Data is smoothed using a 7-day moving average and an offset of three weeks is applied in order to better correlate with the number of deaths by taking into account an approximation of incubation and treatment period lengths.',
      url: 'https://www.ncei.noaa.gov/access/search/data-search/global-summary-of-the-day',
    },
    'Private gatherings - 10 or less people': {
      label: 'Days w/ restrictions on gatherings less than 10',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'days',
      description: 'Record the cut-off size for bans on private gatherings',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Private gatherings - 101 to 1000 people': {
      label: 'Days w/ restrictions on gatherings (101-1k people)',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'days',
      description: 'Record the cut-off size for bans on private gatherings',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Private gatherings - 11 to 100 people': {
      label: 'Days w/ restrictions on gatherings (10-100 people)',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'days',
      description: 'Record the cut-off size for bans on private gatherings',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Private gatherings - above 1000 people': {
      label: 'Days w/ restrictions on gatherings (+1k people)',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'days',
      description: 'Record the cut-off size for bans on private gatherings',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Private gatherings - days with no restrictions': {
      label: 'Days w/o measures for restrictions on gatherings',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'days',
      description: 'Record the cut-off size for bans on private gatherings',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Public events - days with no measures': {
      label: 'Days w/o measures for cancellation of public events',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'days',
      description: 'Record cancelling public events',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Public events - recommend cancelling': {
      label: 'Days w/ recommendation for cancelling public events',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'days',
      description: 'Record cancelling public events',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Public events - require cancelling': {
      label: 'Days w/ required cancellation of public events',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'days',
      description: 'Record cancelling public events',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Public info campaigns - days with no campaign': {
      label: 'Days w/o measures on public campaign information',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'days',
      description: 'Record presence of public info campaigns',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Public info campaigns - officials urging caution': {
      label: 'Days w/ public info campaigns (officials urging caution)',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'days',
      description: 'Record presence of public info campaigns',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Public transport - days with no measures': {
      label: 'Days w/o measures for public transport not closing',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'days',
      description: 'Record closing of public transport',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Public transport - recommend closing': {
      label: 'Days w/ reduced availability of public transport',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'days',
      description: 'Record closing of public transport',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Public transport - require closing': {
      label: 'Days w/ public transport use restricted for most',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'days',
      description: 'Record closing of public transport',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Residential percent change from baseline': {
      label: 'Mobility trends for residential areas',
      category: 'Mobility',
      source: 'Google',
      unit: '% change from baseline',
      description: 'Mobility trends for places of residence. Changes for each day are compared to a baseline value for that day of the week. These datasets show how visits and length of stay at different places change compared to a baseline. We use an offset of three weeks in order to better correlate with the number of deaths by taking into account an approximation of incubation and treatment period lengths.',
      url: 'https://www.google.com/covid19/mobility/',
    },
    'Schools - days with no measures': {
      label: 'Days w/o measures for schools/universities closing',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'days',
      description: 'Record closings of schools and universities.  School closing: in some places, all schools have been shut; in other places, universities closed on a different timescale than  primary schools; in other places still, schools remain open only for the children of essential workers\nOrdinal scale + binary for geographic scope',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Schools - recommend closing': {
      label: 'Days w/ recommendation for schools/univ. closing',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'days',
      description: 'Record closings of schools and universities.  School closing: in some places, all schools have been shut; in other places, universities closed on a different timescale than  primary schools; in other places still, schools remain open only for the children of essential workers\nOrdinal scale + binary for geographic scope',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Schools - require closing (all levels)': {
      label: 'Days w/ required schools/univ. closing - all levels',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'days',
      description: 'Record closings of schools and universities.  School closing: in some places, all schools have been shut; in other places, universities closed on a different timescale than  primary schools; in other places still, schools remain open only for the children of essential workers\nOrdinal scale + binary for geographic scope',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Schools - require closing (only some levels)': {
      label: 'Days w/ required schools/univ. closing - some levels',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'days',
      description: 'Record closings of schools and universities.  School closing: in some places, all schools have been shut; in other places, universities closed on a different timescale than  primary schools; in other places still, schools remain open only for the children of essential workers\nOrdinal scale + binary for geographic scope',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Share infected with HIV (% of population)': {
      label: 'HIV infection as % of total population aged 15 and 49',
      category: 'Health',
      source: 'Our World in Data',
      unit: '% of pop. aged 15-49',
      description: 'Share of the population aged between 15 and 49 years old infected with HIV/AIDS.',
      url: 'https://ourworldindata.org/grapher/share-of-population-infected-with-hiv-ihme',
    },
    'Share of men who are obese (% male population)': {
      label: 'Male obesity as percentage of total male population',
      category: 'Health',
      source: 'World Health Organization',
      unit: '% of male population',
      description: 'Percentage of males aged 18 and older with BMI above 30',
      url: 'https://apps.who.int/gho/data/view.main.CTRY2450A?lang=en',
    },
    'Share of men who smoke (% male population)': {
      label: 'Smoking prevalence - males',
      category: 'Health',
      source: 'World Bank',
      unit: '% of adults',
      description: 'Prevalence of smoking, male is the percentage of men ages 15 and over who currently smoke any tobacco product on a daily or non-daily basis. It excludes smokeless tobacco use. The rates are age-standardized.',
      url: 'https://data.worldbank.org/indicator/sh.prv.smok.ma',
    },
    'Share of women who are obese (% female population)': {
      label: 'Female obesity as percentage of total female population',
      category: 'Health',
      source: 'World Health Organization',
      unit: '% of female population',
      description: 'Percentage of females aged 18 and older with BMI above 30',
      url: 'https://apps.who.int/gho/data/view.main.CTRY2450A?lang=en',
    },
    'Share of women who smoke (% female population)': {
      label: 'Smoking prevalence - females',
      category: 'Health',
      source: 'World Bank',
      unit: '% of adults',
      description: 'Prevalence of smoking, female is the percentage of women ages 15 and over who currently smoke any tobacco product on a daily or non-daily basis. It excludes smokeless tobacco use. The rates are age-standardized.',
      url: 'https://data.worldbank.org/indicator/sh.prv.smok.fe',
    },
    'Share that is 70 years and older (% of population)': {
      label: 'Share of the population that is +70 years',
      category: 'Demographics',
      source: 'Our World in Data',
      unit: '% of population',
      description: 'Population by broad age groups, both Sexes',
      url: 'https://ourworldindata.org/grapher/share-of-the-population-that-is-70-years-and-older',
    },
    'Shelter-in-place - days with no measures': {
      label: 'Days w/o measures for population to stay at home',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'days',
      description: 'Record orders to “shelter-in- place” and otherwise confine to home',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Shelter-in-place - recommend not leaving house': {
      label: 'Days w/ recommendation for population not leave home',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'days',
      description: 'Record orders to “shelter-in- place” and otherwise confine to home',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Shelter-in-place - require not leaving house (with exceptions)': {
      label: 'Days w/ population required stay at home (w/ exceptions)',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'days',
      description: 'Record orders to “shelter-in- place” and otherwise confine to home',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Shelter-in-place - require not leaving house (with minimal exceptions)': {
      label: 'Days w/ stay-at-home requirement (minimal exceptions)',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'days',
      description: 'Record orders to “shelter-in- place” and otherwise confine to home',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Testing policy - anyone with symptoms': {
      label: 'Days testing anyone w/ symptoms',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'days',
      description: 'Policies about testing for having an infection (PCR tests - Polymerase Chain Reaction) - not for policies about testing for immunity (antibody tests)',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Testing policy - days with no testing policy': {
      label: 'Days w/o testing framework',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'days',
      description: 'Policies about testing for having an infection (PCR tests - Polymerase Chain Reaction) - not for policies about testing for immunity (antibody tests)',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Testing policy - only symptoms/specific criteria': {
      label: 'Days testing people w/ symptoms or by specific criteria',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'days',
      description: 'Policies about testing for having an infection (PCR tests - Polymerase Chain Reaction) - not for policies about testing for immunity (antibody tests)',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Testing policy - open public testing': {
      label: 'Days w/ open/asymptomatic public testing',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'days',
      description: 'Policies about testing for having an infection (PCR tests - polymerase chain reaction) - not for policies about testing for immunity (antibody tests)',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Transit stations percent change from baseline': {
      label: 'Mobility trends for transit stations (% from baseline)',
      category: 'Mobility',
      source: 'Google',
      unit: '% change from baseline',
      description: 'Mobility trends for places like public transport hubs such as subway, bus, and train stations. Changes for each day are compared to a baseline value for that day of the week. These datasets show how visits and length of stay at different places change compared to a baseline. We use an offset of three weeks in order to better correlate with the number of deaths by taking into account an approximation of incubation and treatment period lengths.',
      url: 'https://www.google.com/covid19/mobility/',
    },
    'Urban areas over 1M inhabitants': {
      label: 'No of urban areas - over 1 million inhabitants',
      category: 'Demographics',
      source: 'Demographia',
      unit: 'No of urban areas',
      description: "Population in urban agglomerations of more than one million is the percentage of a country's population living in metropolitan areas that in 2018 had a population of more than one million people.",
      url: 'http://www.demographia.com/db-worldua.pdf',
    },
    'Urban areas over 2M inhabitants': {
      label: 'No of urban areas - over 2 million inhabitants',
      category: 'Demographics',
      source: 'Demographia',
      unit: 'No of urban areas',
      description: "Population in urban agglomerations of more than two million is the percentage of a country's population living in metropolitan areas that in 2018 had a population of more than two million people.",
      url: 'http://www.demographia.com/db-worldua.pdf',
    },
    'Urban areas over 500k inhabitants': {
      label: 'Number of urban area w/ over 500k inhabitants',
      category: 'Demographics',
      source: 'Demographia',
      unit: 'Number of urban areas by country',
      description: "Population in urban agglomerations of more than half million is the percentage of a country's population living in metropolitan areas that in 2017 had a population of more than half million people.",
      url: 'http://www.demographia.com/db-worldua.pdf\nhttps://en.wikipedia.org/wiki/Number_of_urban_areas_by_country',
    },
    'Urban areas over 5M inhabitants': {
      label: 'No of urban areas - over 5 million inhabitants',
      category: 'Demographics',
      source: 'Demographia',
      unit: 'No of urban areas',
      description: "Population in urban agglomerations of more than five million is the percentage of a country's population living in metropolitan areas that in 2018 had a population of more than five million people.",
      url: 'http://www.demographia.com/db-worldua.pdf',
    },
    'Urban population (% population)': {
      label: 'People living in urban area',
      category: 'Demographics',
      source: 'World Bank',
      unit: '% of population',
      description: 'Urban population refers to people living in urban areas as defined by national statistical offices. The data are collected and smoothed by United Nations Population Division.',
      url: 'https://data.worldbank.org/indicator/SP.URB.TOTL.in.zs',
    },
    'Vaccination policy - available for ALL the risk groups': {
      label: 'Days w/ vaccination available for ALL priority groups',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'days',
      description: 'Record vaccination availability. Key workers, clinically vulnerable and elderly people are considered the priority groups',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Vaccination policy - available for ALL the risk groups plus partial additional availability': {
      label: 'Days w/ vaccination for ALL priority and other groups',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'days',
      description: 'Record vaccination availability. Key workers, clinically vulnerable and elderly people are considered the priority groups',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Vaccination policy - available for ONE of the risk groups': {
      label: 'Days w/ vaccination for ONE of the priority groups',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'days',
      description: 'Record vaccination availability. Key workers, clinically vulnerable and elderly people are considered the priority groups',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Vaccination policy - available for TWO of the risk groups': {
      label: 'Days w/ vaccination for TWO of the priority groups',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'days',
      description: 'Record vaccination availability. Key workers, clinically vulnerable and elderly people are considered the priority groups',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Vaccination policy - days with no availability': {
      label: 'Days w/ no vaccination availability',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'days',
      description: 'Record vaccination availability',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Vaccination policy - universal availability': {
      label: 'Days w/ vaccination available for everyone',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'days',
      description: 'Record vaccination availability',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Workplaces - days with no measures': {
      label: 'Days w/o measures for workplaces closing',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'days',
      description: 'Record closings of workplaces',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Workplaces - recommend closing': {
      label: 'Days w/ recommendation for workplaces closing',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'days',
      description: 'Record closings of workplaces',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Workplaces - require closing (all-but-essential)': {
      label: 'Days w/ workplaces closed (all-but-essential)',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'days',
      description: 'Record closings of workplaces',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Workplaces - require closing (for some categories)': {
      label: 'Days w/ required workplaces closing for some sectors',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'days',
      description: 'Record closings of workplaces',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'transmission rate (R)': {
      label: 'Transmission rate (R)',
      category: 'Health',
      source: 'Our World in Data',
      unit: 'points',
      description: 'Real-time estimate of the effective reproduction rate (R) of COVID-19. More information at \nhttps://github.com/crondonm/TrackingR/tree/main/Estimates-Database',
      url: 'http://covid.ourworldindata.org/',
    },
    'cumulative number of tests performed (per 100k)': {
      label: 'Cumulative number of tests performed',
      category: 'Health',
      source: 'Our World in Data',
      unit: '/100k',
      description: 'Cumulative number of tests performed',
      url: 'http://covid.ourworldindata.org/',
    },
    'Vaccinated people (% of population)': {
      label: 'Vaccinated people',
      category: 'Health',
      source: 'Our World in Data',
      unit: '% of population',
      description: 'Share of the population that received at least one covid-19 vaccine dose',
      url: 'http://covid.ourworldindata.org/',
    },
    'Fully vaccinated people (% of population)': {
      label: 'Fully vaccinated people',
      category: 'Health',
      source: 'Our World in Data',
      unit: '% of population',
      description: 'Share of the population that received all required doses of any covid-19 vaccine',
      url: 'http://covid.ourworldindata.org/',
    },
    'stringency index': {
      label: 'Stringency index',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'points',
      description: 'Stringency index (contains all C indicators, plus H1 which records public information campaigns). More information at https://github.com/OxCGRT/covid-policy-tracker/blob/master/documentation/index_methodology.md',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'government response index': {
      label: 'Government response index',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'points',
      description: 'Overall government response index (all indicators). More information at https://github.com/OxCGRT/covid-policy-tracker/blob/master/documentation/index_methodology.md',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'containment and health index': {
      label: 'Containment and health index',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'points',
      description: 'Containment and health index (all C and H indicators). More information at https://github.com/OxCGRT/covid-policy-tracker/blob/master/documentation/index_methodology.md',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'economic support index': {
      label: 'Economic support index',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'points',
      description: 'Economic support index (all E indicators). More information at https://github.com/OxCGRT/covid-policy-tracker/blob/master/documentation/index_methodology.md',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Protection of elderly people - days with no measures': {
      label: 'Isolation of the elderly (no measures)',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'days',
      description: 'Record protection of elderly people. Specially those in Long-Term Care Facilities',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Protection of elderly people - recommend isolation': {
      label: 'Isolation for the elderly (recommendation)',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'days',
      description: 'Record protection of elderly people. Specially those in Long-Term Care Facilities',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Protection of elderly people - narrow restriction for isolation': {
      label: 'Isolation of the elderly (narrow restriction)',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'days',
      description: 'Record protection of elderly people. Specially those in Long-Term Care Facilities',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    'Protection of elderly people - extensive restriction for isolation': {
      label: 'Isolation of the elderly (extensive restriction)',
      category: 'Policies',
      source: 'University of Oxford',
      unit: 'days',
      description: 'Record protection of elderly people. Specially those in Long-Term Care Facilities',
      url: 'https://www.bsg.ox.ac.uk/research/research-projects/oxford-covid-19-government-response-tracker',
    },
    p1_present: {
      label: 'Lineage P.1 (Brazilian variant) is present',
      category: 'Health',
      source: 'Pangolin - Phylogenetic Assignment of Named Global Outbreak Lineages',
      unit: 'Binary (Yes/No)',
      description: 'Record if Coronavirus Variant of Concern P.1 (originated in Brazil) was identified in the country',
      url: 'https://github.com/cov-lineages/grinch/tree/main/grinch/data',
    },
    p1_days: {
      label: 'Days w/ presence of lineage P.1 (Brazil variant)',
      category: 'Health',
      source: 'Pangolin - Phylogenetic Assignment of Named Global Outbreak Lineages',
      unit: 'days',
      description: 'Record the number of days since Coronavirus Variant of Concern P.1 (originated in Brazil) was identified in the country',
      url: 'https://github.com/cov-lineages/grinch/tree/main/grinch/data',
    },
    b117_present: {
      label: 'Lineage B.1.1.7 (the UK variant) is present',
      category: 'Health',
      source: 'Pangolin - Phylogenetic Assignment of Named Global Outbreak Lineages',
      unit: 'Binary (Yes/No)',
      description: 'Record if Coronavirus Variant of Concern B.1.1.7 (originated in the UK) was identified in the country',
      url: 'https://github.com/cov-lineages/grinch/tree/main/grinch/data',
    },
    b117_days: {
      label: 'Days w/ presence of lineage B.1.1.7 (the UK variant)',
      category: 'Health',
      source: 'Pangolin - Phylogenetic Assignment of Named Global Outbreak Lineages',
      unit: 'days',
      description: 'Record the number of days since Coronavirus Variant of Concern B.1.1.7 (originated in the UK) was identified in the country',
      url: 'https://github.com/cov-lineages/grinch/tree/main/grinch/data',
    },
    b1351_present: {
      label: 'Lineage B.1.351 (South Africa variant) is present',
      category: 'Health',
      source: 'Pangolin - Phylogenetic Assignment of Named Global Outbreak Lineages',
      unit: 'Binary (Yes/No)',
      description: 'Record if Coronavirus Variant of Concern B.1.351 (originated at South Africa) was identified in the country',
      url: 'https://github.com/cov-lineages/grinch/tree/main/grinch/data',
    },
    b1351_days: {
      label: 'Days w/ presence of lineage B.1.351 (South Africa variant)',
      category: 'Health',
      source: 'Pangolin - Phylogenetic Assignment of Named Global Outbreak Lineages',
      unit: 'days',
      description: 'Record the number of days since Coronavirus Variant of Concern B.1.351 (originated in South Africa) was identified in the country',
      url: 'https://github.com/cov-lineages/grinch/tree/main/grinch/data',
    },
  },
};
