import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import {
  Link,
  useHistory,
  useParams,
  useLocation,
} from 'react-router-dom';
import Predictions from '../components/Predictions.jsx';
import ShapWaterfall from '../components/ShapWaterfall.jsx';
import ShapForceArea from '../components/ShapForceArea.jsx';
import ShapForceLine from '../components/ShapForceLine.jsx';
import { loadLocation } from '../data';

function Home({ meta, version }) {
  const { t, i18n } = useTranslation();

  function formatDate(date) {
    return new Date(date).toLocaleDateString(i18n.language, { timeZone: 'UTC' });
  }

  /**
   * Location
   */

  const history = useHistory();
  const { search } = useLocation();
  const { location } = useParams();

  function onLocationChange({ target: { value } }) {
    history.push({ pathname: `/velocity/${value}`, search });
  }

  /**
   * Location
   */

  const [state, setState] = useState();

  const setVelocityPeak = useCallback((value) => {
    setState((s) => ({ ...s, selectedVelocityPeak: value }));
  }, []);

  const setAccelerationPeak = useCallback((value) => {
    setState((s) => ({ ...s, selectedAccelerationPeak: value }));
  }, []);

  const onVelocityPeakSelectChange = useCallback(({ target: { value } }) => {
    setVelocityPeak(parseInt(value, 10));
  }, [setVelocityPeak]);

  const onAccelerationPeakSelectChange = useCallback(({ target: { value } }) => {
    setAccelerationPeak(parseInt(value, 10));
  }, [setAccelerationPeak]);

  useEffect(() => {
    (async () => {
      const { velocity, acceleration } = await loadLocation(version, location);

      setState({
        velocityData: velocity.data,
        velocityPeaks: velocity.peaks,
        accelerationData: acceleration.data,
        accelerationPeaks: acceleration.peaks,
        selectedVelocityPeak: velocity.peaks[0],
        selectedAccelerationPeak: acceleration.peaks[0],
      });
    })();
  }, [version, location]);

  /**
   * Rendering
   */

  if (!state) {
    return null;
  }

  const updatedAt = meta.generated.toLocaleString(i18n.language, { timeZone: 'UTC' });

  const {
    velocityData,
    velocityPeaks,
    selectedVelocityPeak,
    accelerationData,
    accelerationPeaks,
    selectedAccelerationPeak,
  } = state;

  const locationData = [];
  const locationI18n = t('locations', { returnObjects: true });

  meta.locations.forEach((key) => {
    const label = locationI18n[key];
    if (label) locationData.push({ key, label });
  });

  const sourtedLocations = locationData.sort((a, b) => a.label.localeCompare(b.label));

  const velocityPeak = velocityData[selectedVelocityPeak];
  const accelerationPeak = accelerationData[selectedAccelerationPeak];

  const accelActuals = accelerationData.map((d) => Math.abs(d.actual));
  const accelPredicteds = accelerationData.map((d) => Math.abs(d.predicted));
  const accelAxisMax = Math.max(0.1, ...accelActuals, ...accelPredicteds);

  return (
    <div className="container">
      <aside className="sidebar">
        <p className="sidebar-heading">
          <Trans i18nKey="home.sidebar.heading" />
        </p>
        <p>
          <Trans i18nKey="home.sidebar.body">
            <Link to="/methodology" />
          </Trans>
        </p>
        <p className="text-muted">
          <strong>{t('home.sidebar.updatedAt')}</strong>
          <br />
          {updatedAt}
        </p>
      </aside>
      <main className="main">
        <div className="control-group">
          <div className="control-label">{t('home.selectLocation')}</div>
          <select
            className="control-select"
            onChange={onLocationChange}
            value={location}
          >
            {sourtedLocations.map(({ key, label }) => (
              <option key={key} value={key}>
                {label}
              </option>
            ))}
          </select>
        </div>
        {/**
         * Velocity
         */}
        <section className="section">
          <h2 className="main-title">{t('home.velocity.histogram.title')}</h2>
          <p>{t('home.velocity.histogram.description')}</p>
          <Predictions
            data={velocityData}
            min={0}
            max={0.2}
            markers={velocityPeaks}
            selected={selectedVelocityPeak}
          />
        </section>
        <section className="section">
          <h3 className="section-title">{t('home.velocity.peak.title')}</h3>
          <div className="control-group">
            <div className="control-info">{t('home.velocity.peak.description')}</div>
            <select
              className="control-select"
              onChange={onVelocityPeakSelectChange}
              value={selectedVelocityPeak}
            >
              {velocityPeaks.map((i) => (
                <option key={i} value={i}>
                  {formatDate(velocityData[i].date)}
                </option>
              ))}
            </select>
          </div>
        </section>
        <section className="section">
          <h3 className="section-title">{t('home.velocity.waterfall.title')}</h3>
          <p>{t('home.velocity.waterfall.description')}</p>
          <ShapWaterfall data={velocityPeak} />
        </section>
        <section className="section">
          <h3 className="section-title">{t('home.velocity.featuresArea.title')}</h3>
          <p>{t('home.velocity.featuresArea.description')}</p>
          <ShapForceArea
            data={velocityData}
            sortIndex={selectedVelocityPeak}
            markers={velocityPeaks}
            selected={selectedVelocityPeak}
          />
        </section>
        <section className="section">
          <h3 className="section-title">{t('home.velocity.featuresLine.title')}</h3>
          <p>{t('home.velocity.featuresLine.description')}</p>
          <ShapForceLine
            data={velocityData}
            sortIndex={selectedVelocityPeak}
            markers={velocityPeaks}
            selected={selectedVelocityPeak}
          />
        </section>
        {/**
         * Acceleration
         */}
        <section className="section">
          <h2 className="main-title">{t('home.acceleration.histogram.title')}</h2>
          <p>{t('home.acceleration.histogram.description')}</p>
          <Predictions
            data={accelerationData}
            min={-accelAxisMax}
            max={accelAxisMax}
            markers={accelerationPeaks}
            selected={selectedAccelerationPeak}
          />
        </section>
        <section className="section">
          <h3 className="section-title">{t('home.acceleration.peak.title')}</h3>
          <div className="control-group">
            <div className="control-info">{t('home.acceleration.peak.description')}</div>
            <select
              className="control-select"
              onChange={onAccelerationPeakSelectChange}
              value={selectedAccelerationPeak}
            >
              {accelerationPeaks.map((i) => (
                <option key={i} value={i}>
                  {formatDate(accelerationData[i].date)}
                </option>
              ))}
            </select>
          </div>
        </section>
        <section className="section">
          <h3 className="section-title">{t('home.acceleration.waterfall.title')}</h3>
          <p>{t('home.acceleration.waterfall.description')}</p>
          <ShapWaterfall data={accelerationPeak} />
        </section>
        <section className="section">
          <h3 className="section-title">{t('home.acceleration.featuresArea.title')}</h3>
          <p>{t('home.acceleration.featuresArea.description')}</p>
          <ShapForceArea
            data={accelerationData}
            sortIndex={selectedAccelerationPeak}
            markers={accelerationPeaks}
            selected={selectedAccelerationPeak}
          />
        </section>
        <section className="section">
          <h3 className="section-title">{t('home.acceleration.featuresLine.title')}</h3>
          <p>{t('home.acceleration.featuresLine.description')}</p>
          <ShapForceLine
            data={accelerationData}
            sortIndex={selectedAccelerationPeak}
            markers={accelerationPeaks}
            selected={selectedAccelerationPeak}
          />
        </section>
      </main>
    </div>
  );
}

Home.propTypes = {
  meta: PropTypes.object.isRequired,
  version: PropTypes.string.isRequired,
};

export default Home;
