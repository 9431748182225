import * as d3 from 'd3';

function normalize(xs) {
  return xs.map((x) => (x - d3.mean(xs)) / d3.deviation(xs));
}

function peakiness(l, v, r) {
  return v - d3.max([d3.min(l) || 0, d3.min(r) || 0]);
}

function detectPeaks(values, options) {
  const {
    lookaround = 2,
    sensitivity = 1.4,
    coalesce = 0,
    full = false,
  } = options;

  // Compute a peakiness score for every sample value in `data`
  // We normalize the scale of the scores by mean-centering and dividing by the standard deviation
  // to get a dimensionless quantity such that can be used as a sensitivity parameter
  // across different scales of data (s. t. normalize(x) == normalize(k*x))
  const scores = normalize(
    values.map((value, index) => peakiness(
      values.slice(Math.max(0, index - lookaround), index),
      value,
      values.slice(index + 1, index + lookaround + 1),
    )),
  );

  // Candidate peaks are indices whose score is above the sensitivity threshold
  const candidates = d3.range(scores.length)
    .filter((index) => scores[index] > sensitivity);

  // If we have multiple peaks, coalesce those that are close together
  const groups = candidates.length ? [[candidates[0]]] : [];

  d3.pairs(candidates).forEach(([a, b]) => {
    if (b - a < coalesce) {
      groups[groups.length - 1].push(b);
    } else {
      groups.push([b]);
    }
  });

  // Represent every group of peaks by the highest peak in the group
  const peaks = groups.map((group) => {
    const index = d3.scan(group, (a, b) => values[b] - values[a]);
    return group[index];
  });

  return full ? {
    data: values, values, scores, candidates, groups, peaks,
  } : peaks;
}

export default detectPeaks;
