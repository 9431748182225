import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router } from 'react-router-dom';

import Layout from './Layout.jsx';

import './i18n';
import './styles/index.css';

export default function App() {
  return (
    <Suspense fallback={<div />}>
      <Router>
        <Layout />
      </Router>
    </Suspense>
  );
}

ReactDOM.render(<App />, document.getElementById('app'));
