import { ROOT } from './config';

async function loadMeta(path, root = ROOT) {
  const response = await fetch(`${root}/${path}/metadata.json`);
  const data = await response.json();

  return {
    generated: new Date(data.generated),
    // TODO: Remove this fallback support.
    locations: Array.isArray(data.countries) ? data.countries : Object.keys(data.countries),
    features: Array.isArray(data.features) ? data.features : Object.keys(data.features),
  };
}

export default loadMeta;
