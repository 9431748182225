import * as d3 from 'd3';
import React, { useEffect, useState, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  NavLink,
  Redirect,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';
import Track from './Track.jsx';
import Faq from './pages/Faq.jsx';
import Home from './pages/Home.jsx';
import Methodology from './pages/Methodology.jsx';
import { loadMeta, loadVersions } from './data';

import logo from '../assets/logo.svg';

function Layout() {
  const { t, i18n } = useTranslation();

  useLayoutEffect(() => {
    document.title = t('title');

    d3.formatDefaultLocale(t('format', { returnObjects: true }));
    d3.timeFormatDefaultLocale(t('timeFormat', { returnObjects: true }));
  }, [t]);

  const [paths, setPaths] = useState();
  const [meta, setMeta] = useState();

  const { search } = useLocation();

  const query = new URLSearchParams(search);
  const version = query.get('version') || (paths ? paths.latest : null);

  useEffect(() => {
    (async () => setPaths(await loadVersions()))();
  }, []);

  useEffect(() => {
    (async () => version && setMeta(await loadMeta(version)))();
  }, [version]);

  const isWorld = window.location.pathname === '/';
  const isBrazil = window.location.pathname === '/brazil/';

  return !meta ? null : <>
    <div className="topbar">
      <h1 className="topbar-title">{t('title')}</h1>
      <a className="topbar-logo" href="https://kunumi.com">
        <img src={logo} />
      </a>
    </div>
    <div className="primary-nav">
      <nav className="nav">
        <a className={`nav-link ${isWorld ? 'active' : ''}`} href="/#/">
          {t('nav.world')}
        </a>
        <a className={`nav-link ${isBrazil ? 'active' : ''}`} href="/brazil/#/">
          {t('nav.brazil')}
        </a>
      </nav>
      <div className="language-toggle">
        <a
          type="text"
          className={i18n.language === 'en-US' ? 'active' : ''}
          onClick={() => i18n.changeLanguage('en-US')}
        >
          EN
        </a>
        <a
          type="text"
          className={i18n.language === 'pt-BR' ? 'active' : ''}
          onClick={() => i18n.changeLanguage('pt-BR')}
        >
          PT
        </a>
      </div>
    </div>
    <div className="secondary-nav">
      <nav className="nav">
        <NavLink
          className="nav-link"
          activeClassName="active"
          to="/velocity"
        >
          {t('nav.home')}
        </NavLink>
        <NavLink
          className="nav-link"
          activeClassName="active"
          to="/methodology"
        >
          {t('nav.methodology')}
        </NavLink>
        <NavLink
          className="nav-link"
          activeClassName="active"
          to="/faq"
        >
          {t('nav.faq')}
        </NavLink>
      </nav>
    </div>
    <Switch>
      <Route path="/velocity/:location">
        <Track>
          <Home meta={meta} version={version} />
        </Track>
      </Route>
      <Route path="/methodology">
        <Track>
          <Methodology meta={meta} />
        </Track>
      </Route>
      <Route path="/faq">
        <Track>
          <Faq />
        </Track>
      </Route>
      <Redirect from="/velocity" to={{ pathname: '/velocity/BR', search }} />
      <Redirect from="/" to={{ pathname: '/velocity', search }} />
    </Switch>
  </>;
}

export default Layout;
