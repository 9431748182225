const WithMarkers = (superclass) => class extends superclass {
  renderMarkers(data, markers) {
    const groups = this.chart.selectAll('.chart-marker')
      .data(markers);

    groups.exit().remove();

    const newGroups = groups.enter().append('g')
      .classed('chart-marker', true)
      .attr('opacity', 0.2);

    newGroups.append('line')
      .attr('stroke', '#000000')
      .attr('stroke-width', 1)
      .attr('y1', this.margin.top)
      .attr('y2', this.height - this.margin.bottom);

    this.markers = newGroups.merge(groups);

    this.markers.select('line')
      .attr('x1', (d) => this.xScale(data[d].date) + 0.5)
      .attr('x2', (d) => this.xScale(data[d].date) + 0.5);
  }

  selectMarker(d) {
    this.selectedMarker = d;
    this.updateMarkers();
  }

  updateMarkers() {
    this.markers.attr('opacity', (d) => (d === this.selectedMarker ? 0.8 : 0.2));
  }
};

export default WithMarkers;
